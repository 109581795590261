import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'USUARIO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('usuarioForm', registro)
    ];
}

export function getLista(id_empresa) {
    const request = axios.get(`${consts.API_URL}/usuario?id_empresa=${id_empresa}`);
    return {
        type: 'USUARIO_LISTADO',
        payload: request
    };
}

export function getListaUsuarioTipo(id_empresa) {
    const request = axios.get(`${consts.API_URL}/usuariotipo/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'USUARIO_TIPO_SELECT_LISTADO',
        payload: request
    };
}

export function changeValueUsuarioTipo(e) {
    return {
        type: 'USUARIO_TIPO_VALUE_CHANGED',
        payload: e.target.value
    }
}

export function salvar(registro) {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/usuario`, {
				...registro,
				id_empresa: getState().auth.usuario.id_empresa
			})
            .then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(getState().auth.usuario.id_empresa));
                dispatch(setModoTela('lista'));
            })
            .catch(e => {
                setErroAPI(e);
            });
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/usuario?id=${registro.id}`)
            .then(resp => {
                setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(getState().auth.usuario.id_empresa));
                dispatch(setModoTela('lista'));
            })
            .catch(e => {
                setErroAPI(e);
            });
    };
}
