const INITIAL_STATE = {
	registro: {},
	modoTela: 'lista',
	lista: [],
	listaAnimal: [],
	listaGranjas: [],
	filtro: {
		status: 1,
		id_granja: null,
		lote: '',
		brinco: ''
	}
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'LOTE_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};
		case 'LOTE_SET_FILTRO':
            return { ...state,
				filtro: action.payload
			};
        case 'LOTE_LISTADO':
            return { ...state, modoTela: 'lista', lista: action.payload };
		case 'LOTE_ANIMAL_LISTADO':
            return { ...state, listaAnimal: action.payload.data };
        case 'LOTE_GRANJA_SELECT_LISTADO':
            return { ...state, listaGranjas: action.payload.data };
        default:
            return state;
    }
}
