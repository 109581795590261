import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import { getDataAtual, getDataFormatada, getData, getDataHoraBanco, getDataHoraFormatada } from '../utils/dataUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'ENTRADA_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = { datahora_entrada: getDataAtual() }) {
    return [
        initialize('entradaForm', {
			...registro,
			datahora_entrada: getDataHoraFormatada(registro.datahora_entrada, 'DD/MM/YYYY HH:mm')
		})
    ];
}

export function getLista(id_empresa) {
    const request = axios.get(`${consts.API_URL}/entrada?id_empresa=${id_empresa}`);
    return {
        type: 'ENTRADA_LISTADO',
        payload: request
    };
}

export function getListaGranja(id_empresa) {
    const request = axios.get(`${consts.API_URL}/granja/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'ENTRADA_GRANJA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaFornecedor(id_empresa) {
    const request = axios.get(`${consts.API_URL}/pessoa/listarselect?id_empresa=${id_empresa}&fornecedor=true`);
    return {
        type: 'ENTRADA_FORNECEDOR_SELECT_LISTADO',
        payload: request
    };
}

export function getListaProduto(id_empresa) {
    const request = axios.get(`${consts.API_URL}/produto/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'ENTRADA_PRODUTO_SELECT_LISTADO',
        payload: request
    };
}

export function salvar(registro) {

    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/entrada`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa,
			datahora_entrada: getDataHoraBanco(registro.datahora_entrada)
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(getState().auth.usuario.id_empresa));
            dispatch(setModoTela('lista'));
        }).catch(e => {
            setErroAPI(e);
        });
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/entrada?id=${registro.id}`)
            .then(resp => {
                setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(getState().auth.usuario.id_empresa));
                dispatch(setModoTela('lista'));
            })
            .catch(e => {
                setErroAPI(e);
            });
    };
}
