import React from 'react'
import Grid from '../layout/grid'

export default props => (
    <Grid cols={props.cols}>
        <div className='form-group'>
			{props.label ? (
				<label htmlFor={props.name}>{props.label}</label>
			) : null}
			{props.onChange ? (
	            <select
	                {...props.input}
	                className="form-control"
					readOnly={props.readOnly}
					onChange={props.onChange}
					value={props.value} >
	                <option value="0" disabled={props.readOnly ? true : false}>{props.placeholder}</option>
	                {
	                    props.options.map(opt => {
	                        return (
	                            <option disabled={props.readOnly ? true : false}
	                                key={opt.id}
	                                value={opt.id}>{opt.valor}</option>
	                        );
	                    })
	                }
	           </select>
		   ) : (
			   <select
                   {...props.input}
                   className="form-control"
   				readOnly={props.readOnly} >
                   <option value="0" disabled={props.readOnly ? true : false}>{props.placeholder}</option>
                   {
                       props.options.map(opt => {
                           return (
                               <option disabled={props.readOnly ? true : false}
                                   key={opt.id}
                                   value={opt.id}>{opt.valor}</option>
                           );
                       })
                   }
              </select>
		  )}
       </div>
   </Grid>
)
