import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import Button from '../common/button/button';
import ReactToPdf from 'react-to-pdf';

import { getLista, getListaGranja } from './relatorioInspetoriaVeterinariaActions';
import { getDataAtual, getDataFormatada, getData, getDataHoraBanco, getDataHoraFormatada } from '../utils/dataUtils';

class RelatorioInspetoriaVeterinaria extends Component {

	componentDidMount() {
		let contentWrapper = document.getElementById('id-content-wrapper');
		contentWrapper.classList.remove('content-wrapper');
	}

	componentWillUnmount() {
		let contentWrapper = document.getElementById('id-content-wrapper');
		contentWrapper.classList.add('content-wrapper');
	}


    componentWillMount() {
		this.props.getLista(0, this.props.sessao.id_empresa);
        this.props.getListaGranja(this.props.sessao.id_empresa);
    }

    render() {

		const ref = React.createRef();

		const container = {
			marginLeft: 210
		};

		const principal = {
			width: 793,
			height: 1122,
			paddingLeft: 40,
			paddingRight: 40,
			paddingTop: 25
		};

		const borda = {
			borderStyle: 'solid',
			borderTopWidth: 1,
			borderBottomWidth: 0,
			borderLeftWidth: 1,
			borderRightWidth: 1,
			borderColor: '#000'
		};

		const bordaLateral = {
			borderStyle: 'solid',
			borderTopWidth: 0,
			borderBottomWidth: 0,
			borderLeftWidth: 1,
			borderRightWidth: 1,
			borderColor: '#000'
		};

		const bordaSeparacaoGrafico = {
			borderStyle: 'solid',
			borderTopWidth: 0,
			borderBottomWidth: 0,
			borderLeftWidth: 0,
			borderRightWidth: 1,
			borderColor: '#000'
		};

		const bordaFinal = {
			borderStyle: 'solid',
			borderTopWidth: 1,
			borderBottomWidth: 1,
			borderLeftWidth: 1,
			borderRightWidth: 1,
			borderColor: '#000'
		};

		let granja = null;

		let quantidadeFemea = 0;
		let quantidadeMacho = 0;

		return (
			<div style={container}>
				<ReactToPdf targetRef={ref} filename={`Relatório Inspetoria Veterinária - ${this.state && this.state.granja ? this.state.granja.nome : ''}.pdf`} options={{ orientation: 'portrait' }}>
					{({toPdf}) => (
						<div style={{ marginTop: 60, marginLeft: 40 }}>
							<Row>
								<Grid cols='4'>
									<div className='form-group'>
										<select className="form-control"
											onChange={(data) => {
												this.props.getLista(data.target.value || 0, this.props.sessao.id_empresa);

												if (data.target.value) {
													this.setState({ ...this.state, granja: this.props.listaGranja.filter(granja => granja.id == data.target.value)[0] });
												} else {
													this.setState({ ...this.state, granja: null });
												}


											}}>
											<option	key={0} value={0}>Selecione a Granja</option>
											{this.props.listaGranja.map(opt => {
												return (
													<option	key={opt.id} value={opt.id}>{opt.nome}</option>
												);
											})}
										</select>
									</div>
								</Grid>
								{this.state && this.state.granja ? (
									<Grid cols='8'>
										<button type='button' className='btn btn-primary' onClick={toPdf}>
											<i className='fa fa-download'> Baixar</i>
										</button>
									</Grid>
								) : null}
							</Row>
						</div>
					)}
				</ReactToPdf>
				<div style={principal} ref={ref}>
					{this.state && this.state.granja ? (
						<div>
							<Row>
								<Grid cols='12'>
									<h4 style={{ fontWeight: 'bold', textAlign: 'center' }}>Saldo de Bovinos</h4>
								</Grid>
								<br />
								<Grid cols='8'>
									<label>Data de Emissão:</label> {getDataHoraFormatada(getDataAtual(), 'DD/MM/YYYY HH:mm:ss')}
									<br />
									<label>CPF:</label> {this.state.granja.cpf}
									<br />
									<label>Inscrição Estadual:</label> {this.state.granja.inscricao_estadual}
									<br />
									<label>Propriedade Rural:</label> {this.state.granja.propriedade_rural} ({this.state.granja.nome})
									<br />
									<label>Grupo Produtor:</label> {this.state.granja.grupo_produtor}
								</Grid>

								<Grid cols='4'>
									<label>Município: </label> {this.state.granja.nome_cidade}
									<br />
									<label>Localidade:</label> {this.state.granja.endereco}
									<br />
									<label>Zona:</label> {this.state.granja.zona}
								</Grid>
							</Row>
						</div>
					) : null}
					<br />
					{this.state && this.state.granja ? (
						<Row>
							<Grid cols='12'>
								<Table>
									<THead>
										<Tr>
											<Th>Idade</Th>
											<Th alignRight>Fêmea</Th>
											<Th alignRight>Macho</Th>
											<Th alignRight>Total</Th>
										</Tr>
									</THead>
									<TBody>
										{this.props.lista.map(item => {

											quantidadeFemea += parseInt(item.quantidade_femea);
											quantidadeMacho += parseInt(item.quantidade_macho);

											return (
												<Tr key={item.idade}>
													<Td>{item.idade}</Td>
													<Td alignRight>{item.quantidade_femea}</Td>
													<Td alignRight>{item.quantidade_macho}</Td>
													<Td alignRight>{parseInt(item.quantidade_femea) + parseInt(item.quantidade_macho)}</Td>
												</Tr>
											)
										})}
										<Tr>
											<Th>Total</Th>
											<Th alignRight>{quantidadeFemea}</Th>
											<Th alignRight>{quantidadeMacho}</Th>
											<Th alignRight>{quantidadeFemea + quantidadeMacho}</Th>
										</Tr>
									</TBody>
								</Table>
							</Grid>
						</Row>
					) : null}
				</div>
			</div>
		);
    }

}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	lista: state.relatorioInspetoriaVeterinaria.lista,
	listaGranja: state.relatorioInspetoriaVeterinaria.listaGranja
});
const mapDispatchToProps = dispatch => bindActionCreators({ getLista, getListaGranja }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(RelatorioInspetoriaVeterinaria);
