import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Button from '../common/button/button';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import Select from '../common/form/select';

import { setModoTela, getListaFamilia, getListaSexo, getListaRaca } from './previsaoPesoActions';

class PrevisaoPesoForm extends Component {

    componentWillMount() {
		this.props.getListaFamilia();
		this.props.getListaSexo();
		this.props.getListaRaca();
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<Row>
						<Field
							name='id_familia'
							component={Select}
							options={this.props.listaFamilia}
							label='Família'
							cols='12 10 6 4'
							placeholder='Selecione a Família'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_sexo'
							component={Select}
							options={this.props.listaSexo}
							label='Sexo'
							cols='12 10 6 4'
							placeholder='Selecione o Sexo'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_raca'
							component={Select}
							options={this.props.listaRaca}
							label='Raça'
							cols='12 10 6 4'
							placeholder='Selecione a Raça'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='idade_inicio'
							component={LabelAndInputNumber}
							label='Idade Inicial (meses) *'
							placeholder='Informe a Idade Inicial'
							casas={0}
							cols='12 5 3 2'
							readOnly={readOnly} />
						<Field
							name='idade_fim'
							component={LabelAndInputNumber}
							label='Idade Final (meses) *'
							placeholder='Informe a Idade Final'
							casas={0}
							cols='12 5 3 2'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='peso'
							component={LabelAndInputNumber}
							label='Peso *'
							placeholder='Informe o Peso'
							casas={2}
							cols='12 5 3 2'
							readOnly={readOnly} />
					</Row>
					<BoxButtonFooter>
						{this.props.excluir ? (
							<Button
								text='Excluir'
								submit type={'danger'}
								icon={'fa fa-trash'} />
						) : (
							<Button
								text='Salvar'
								submit
								type={'success'}
								icon={'fa fa-check'} />
						)}
						<Button
							text='Cancelar'
							type={'warning'}
							icon={'fa fa-chevron-left'}
							event={() => this.props.setModoTela('lista')} />
					</BoxButtonFooter>
				</Form>
			</ContentCard>
        )
    }


}

PrevisaoPesoForm = reduxForm({form: 'previsaoPesoForm', destroyOnUnmount: false})(PrevisaoPesoForm);
const mapStateToProps = state => ({
	listaFamilia: state.previsaoPeso.listaFamilia,
	listaSexo: state.previsaoPeso.listaSexo,
	listaRaca: state.previsaoPeso.listaRaca,
	registro: state.previsaoPeso.registro
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, getListaFamilia, getListaSexo, getListaRaca }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PrevisaoPesoForm);
