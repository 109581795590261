import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputDate from '../common/form/labelAndInputDate';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import NumberFormat from 'react-number-format';

import { setModoTela, getListaGranja, getListaLocal, getListaAnimal, getListaItens, adicionarItem, alterarItem, removerItem, ratearPeso } from './pesoActions';

class PesoForm extends Component {

	state = {
		filtroLote: '',
		filtroAnimal: '',
		listaItens: [],
		pesoRateio: 0
	};

    componentWillMount() {
		this.props.getListaGranja(this.props.sessao.id_empresa);
		this.props.getListaLocal(this.props.sessao.id_empresa);
		this.props.getListaAnimal(this.props.sessao.id_empresa);
		if (this.props.formularioValues.id) {
			this.props.getListaItens(this.props.formularioValues.id, this.props.sessao.id_empresa);
		} else {
			this.props.getListaItens(0, this.props.sessao.id_empresa);
		}
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		let filtroLote = this.state.filtroLote ? this.state.filtroLote.toUpperCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&') : null;
		let filtroAnimal = this.state.filtroAnimal ? this.state.filtroAnimal.toUpperCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&') : null;
		let listaAnimal = (this.props.listaAnimal || []).filter(animal => {

			if (filtroAnimal && filtroLote) {
				return (`${animal.numero_brinco}`).toUpperCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&').indexOf(filtroAnimal) != -1
					&& (`${animal.numero_lote}`).toUpperCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&').indexOf(filtroLote) != -1;
			} else if (filtroAnimal) {
				return (`${animal.numero_brinco}`).toUpperCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&').indexOf(filtroAnimal) != -1;
			} else if (filtroLote) {
				return (`${animal.numero_lote}`).toUpperCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&').indexOf(filtroLote) != -1;
			} else {
				return null;
			}
		});
		listaAnimal = listaAnimal.filter(animal => {
			let existe = false;
			this.props.listaItens.forEach(itemAnimal => {
				if (itemAnimal.id_animal == animal.id) {
					existe = true;
				}
			});

			return !existe;
		});

		if (this.props.listaItens && (this.state.listaItens.length != this.props.listaItens.length
				|| this.state.listaItens.filter(itemPeso => {
					let retorno = false;
					this.props.listaItens.forEach(itemPesoProps => {
						if (itemPesoProps.id == itemPeso.id && itemPesoProps.datahora_alteracao != itemPeso.datahora_alteracao) {
							retorno = true;
						}
					});
					return retorno;
				}).length > 0)) {
			this.setState({
				...this.state,
				listaItens: this.props.listaItens.map(item => item)
			});
		}

		let listaFaixaIdade = [
			{ nome: '0 - 12', faixaInicial: null, faixaFinal: 12, femea: 0, macho: 0 },
			{ nome: '13 - 24', faixaInicial: 13, faixaFinal: 24, femea: 0, macho: 0 },
			{ nome: '25 - 36', faixaInicial: 25, faixaFinal: 36, femea: 0, macho: 0 },
			{ nome: '36 +', faixaInicial: 37, faixaFinal: null, femea: 0, macho: 0 }
		];
		this.state.listaItens.forEach(item => {
			listaFaixaIdade = listaFaixaIdade.map(faixaIdade => {
				if ((faixaIdade.faixaInicial == null || item.idade >= faixaIdade.faixaInicial)
					&& (faixaIdade.faixaFinal == null || item.idade <= faixaIdade.faixaFinal)) {
					if (item.id_sexo == 1) {
						return {
							...faixaIdade,
							femea: faixaIdade.femea + 1
						}
					} else {
						return {
							...faixaIdade,
							macho: faixaIdade.macho + 1
						}
					}
				}
				return faixaIdade;
			});
		});

		let pesoVivo =  0;
		let pesoCarcaca = 0;
		let valorTotal = 0;

		let totalFemeas = 0;
		let totalMachos = 0;

        return (
			<div>
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<Row>
							<Field
								name='datahora_pesagem'
								component={LabelAndInputDateTime}
								label='Data e hora da Peso'
								placeholder='Informe a data e a hora'
								cols='8 4 3 2'
								readOnly={readOnly} />
						</Row>

						<BoxButtonFooter>
							{this.props.excluir ? (
								<Button
									text='Excluir'
									submit type={'danger'}
									icon={'fa fa-trash'} />
							) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
							)}
							<Button
								text='Voltar'
								type={'warning'}
								icon={'fa fa-chevron-left'}
								event={() => this.props.setModoTela('lista')} />
						</BoxButtonFooter>
					</Form>
				</ContentCard>

				{this.props.formularioValues.id ? (
					<ContentCard>
						<ContentCardHeader title='Itens' />
						<Row>
							<Grid cols='12 8 6'>
								<div style={{ display: 'flex', flex: 2 }}>
									<div style={{ display: 'flex', flex: 2, flexDirection: 'row' }}>
										<div>
											<label>Lote</label>
											<input className='form-control'
												placeholder={'Informe o Lote'}
												value={this.state.filtroLote}
												onChange={(data => {
													this.setState({
														...this.state,
														filtroLote: data.target.value
													});
												})} />
										</div>
										<div>
											<label>Animal</label>
											<input className='form-control'
												placeholder={'Informe o Animal'}
												value={this.state.filtroAnimal}
												onChange={(data => {
													this.setState({
														...this.state,
														filtroAnimal: data.target.value
													});
												})} />
										</div>
									</div>
								</div>
								{listaAnimal.length > 0 ? (
									<div style={{ display: 'flex', overflowX: 'auto', overflowY: 'auto', maxHeight: 150 }}>
										<table className='table table-bordered table-striped dataTable'>
											<THead>
												<Tr>
													<Th>Animal</Th>
													<Th>Lote</Th>
													<Th>Sexo</Th>
													<Th>Idade</Th>
												</Tr>
											</THead>
											<TBody>
												{listaAnimal.map(animal => {
													return (
														<Tr key={animal.id}
															onClick={() => {
																this.props.adicionarItem({
																	id_peso: this.props.formularioValues.id,
																	id_granja: animal.id_granja_destino,
																	id_local: animal.id_local_destino,
																	id_animal: animal.id,
																	peso: 0,
																	score_corporal: 0
																});
															}}>
															<Td>{animal.numero_brinco}</Td>
															<Td>{animal.numero_lote}</Td>
															<Td>{animal.nome_sexo}</Td>
															<Td>{parseInt(animal.idade_atual)}</Td>
														</Tr>
													);
												})}
											</TBody>
										</table>
									</div>
								) : null}

								<br />
							</Grid>
							<Grid cols='12'>
								<Table>
									<THead>
										<Tr>
											<Th>Granja</Th>
											<Th>Lote</Th>
											<Th>Lote</Th>
											<Th>Animal</Th>
											<Th>Sexo</Th>
											<Th>Idade</Th>
											<Th alignCenter>Peso</Th>
											<Th alignCenter>Score</Th>
											<Th alignCenter acoes>Ações</Th>
										</Tr>
									</THead>
									<TBody>
										{this.state.listaItens.map(item => {
											return (
												<Tr key={item.id}>
													<Td>{item.nome_granja}</Td>
													<Td>{item.nome_local}</Td>
													<Td>{item.numero_lote}</Td>
													<Td>{item.numero_brinco_animal}</Td>
													<Td>{item.nome_sexo}</Td>
													<Td>{item.idade}</Td>
													<Td alignRight>
														{item.alteracao ? (
															<NumberFormat
																style={{ textAlign: 'right' }}
																className='form-control'
																decimalSeparator={","} decimalScale={4} fixedDecimalScale={4}
																value={String(item.peso || '0').replace('.', ',')}
																onChange={data => {
																	let lista = this.state.listaItens.map(itemPeso => {
																		if (itemPeso.id == item.id) {
																			return {
																				...itemPeso,
																				peso: data.target.value.replace(',', '.')
																			};
																		} else {
																			return itemPeso;
																		}
																	});
																	this.setState({
																		...this.state,
																		listaItens: lista
																	});
																}} />
														) : (item.peso || '0,0000').replace('.', ',')}
													</Td>
													<Td alignCenter>
														{item.alteracao ? (
															<Select
																options={Array(10).fill(null).map((valor, i) => ({ id: i+1, valor: i+1 }))}
																value={item.score_corporal || 0}
																placeholder={0}
																onChange={(data) => {
																	let lista = this.state.listaItens.map(itemPeso => {
																		if (itemPeso.id == item.id) {
																			return {
																				...itemPeso,
																				score_corporal: data.target.value
																			};
																		} else {
																			return itemPeso;
																		}
																	});
																	this.setState({
																		...this.state,
																		listaItens: lista
																	});
																}}
																label={null}
																cols='12 12 12 12' />
														) : (item.score_corporal || 0)}
													</Td>
													<Td alignCenter>
														{item.alteracao ? (
															<div>
																<ButtonTable
																	type={'success'}
																	icon={'fa fa-check'}
																	event={() => {
																		this.props.alterarItem({
																			...item
																		});
																		this.setState({
																			...this.state,
																			alteracao: false
																		});
																	}} />

																<ButtonTable
																	type={'danger'}
																	icon={'fa fa-times'}
																	event={() => {
																		this.setState({
																			...this.state,
																			listaItens: this.props.listaItens.map(item => item),
																			alteracao: false
																		});
																	}} />
															</div>
														) : (
															<div>
																<ButtonTable
																	type={this.state.alteracao ? 'disabled' : 'warning'}
																	icon={'fa fa-pencil'}
																	disabled={true}
																	event={() => {
																		if (!this.state.alteracao) {
																			let lista = this.state.listaItens.map(itemPeso => {
																				return {
																					...itemPeso,
																					alteracao: itemPeso.id == item.id
																				};
																			});
																			this.setState({
																				...this.state,
																				listaItens: lista,
																				alteracao: true
																			});
																		}
																	}} />

																<ButtonTable
																	type={this.state.alteracao ? 'disabled' : 'danger'}
																	icon={'fa fa-trash-o'}
																	event={() => {
																		if (!this.state.alteracao) {
																			this.props.removerItem({
																				...item,
																				id_granja: this.props.formularioValues.id_granja
																			});
																		}
																	}} />
															</div>
														)}
													</Td>
												</Tr>
											);
										})}
									</TBody>
								</Table>
							</Grid>
							<Grid cols='12'>
								<Tr style={{ height: 40, verticalAlign: 'middle' }}>
									<Td>
										<NumberFormat
											style={{ textAlign: 'left', width: 120 }}
											className='form-control'
											decimalSeparator={","} decimalScale={4} fixedDecimalScale={4}
											value={String(this.state.pesoRateio).replace('.', ',')}
											onChange={data => {
												this.setState({
													...this.state,
													pesoRateio: data.target.value.replace(',', '.')
												});
											}} />
									</Td>
									<Td>
										<Button
											text='Ratear peso'
											type={'success'}
											icon={'fa fa-random'}
											event={() => {
												this.props.ratearPeso(this.props.sessao.id_empresa, this.props.formularioValues.id, this.state.pesoRateio);
											}} />
									</Td>
								</Tr>
								<br />
							</Grid>
							<Grid cols='12 12 6'>
								<Table>
									<THead>
										<Tr>
											<Th alignCenter>Idade</Th>
											<Th alignCenter>Fêmea</Th>
											<Th alignCenter>Macho</Th>
										</Tr>
									</THead>
									<TBody>
										{listaFaixaIdade.map(faixaIdade => {

											totalFemeas += faixaIdade.femea;
											totalMachos += faixaIdade.macho;

											return (
												<Tr key={faixaIdade.nome}>
													<Td alignCenter>{faixaIdade.nome}</Td>
													<Td alignCenter>{faixaIdade.femea}</Td>
													<Td alignCenter>{faixaIdade.macho}</Td>
												</Tr>
											);
										})}
										<Tr>
											<Th alignCenter>Total</Th>
											<Th alignCenter>{totalFemeas}</Th>
											<Th alignCenter>{totalMachos}</Th>
										</Tr>
									</TBody>
								</Table>
							</Grid>
						</Row>
					</ContentCard>
				) : null}
			</div>
        )
    }


}

PesoForm = reduxForm({form: 'pesoForm', destroyOnUnmount: false})(PesoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	listaGranja: state.peso.listaGranja,
	listaLocal: state.peso.listaLocal,
	listaAnimal: state.peso.listaAnimal,
	listaItens: state.peso.listaItens,
	registro: state.peso.registro,
	formularioValues: getFormValues('pesoForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, getListaGranja, getListaLocal, getListaAnimal, getListaItens, adicionarItem, alterarItem, removerItem, ratearPeso }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(PesoForm);
