import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import ContentHeader from '../../common/template/contentHeader';
import Content from '../../common/template/content';
import ContentCard from '../../common/template/contentCard';
import ContentCardHeader from '../../common/template/contentCardHeader';
import Row from '../../common/layout/row';
import Grid from '../../common/layout/grid';
import Button from '../../common/button/button';
import BoxButtonFooter from '../../common/button/boxButtonFooter';
import Form from '../../common/form/form';
import LabelAndInput from '../../common/form/labelAndInput';
import LabelAndCheckbox from '../../common/form/labelAndCheckbox';
import LabelAndInputNumber from '../../common/form/labelAndInputNumber';
import LabelAndInputDate from '../../common/form/labelAndInputDate';
import Select from '../../common/form/select';
import * as moment from 'moment';
import 'moment/locale/pt-br';

import { setModoTela as setModoTelaLote } from '../loteActions';
import { setModoTela, initForm, getListaGranja, getListaFornecedor, getListaLocal, getListaFamilia, getListaSexo, getListaRaca, getListaBrinco, getListaPelagem, getListaMae, getListaLote } from './animalActions';

class AnimalForm extends Component {

    componentWillMount() {
		this.props.getListaLote(this.props.sessao.id_empresa);
		this.props.getListaMae(this.props.sessao.id_empresa);
		this.props.getListaGranja(this.props.sessao.id_empresa);
		this.props.getListaFornecedor(this.props.sessao.id_empresa);
		this.props.getListaLocal(this.props.sessao.id_empresa);
		this.props.getListaFamilia(this.props.sessao.id_empresa);
		this.props.getListaSexo();
		this.props.getListaRaca(this.props.sessao.id_empresa);
		this.props.getListaBrinco(this.props.sessao.id_empresa);
		this.props.getListaPelagem(this.props.sessao.id_empresa);
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		let keyCaracteristica = 0;

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>

					<Row>
						<Field
							name='id_lote'
							component={Select}
							options={this.props.listaLotes}
							label='Lote'
							cols='12 6 6 4'
							placeholder='Selecione o Lote'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_mae'
							component={Select}
							options={this.props.listaMaes.map(item => ({ id: item.id, valor: `Lote: ${item.numero_lote} - Brinco: ${item.numero_brinco}` }))}
							onChange={data => {
								if (this.props.formularioValues.nascimento) {
									let mae = this.props.listaMaes.filter(item => item.id == data.target.value)[0];
									if (mae) {
										this.props.initForm({
											...this.props.formularioValues,
											id_granja_origem: mae.id_granja_destino,
											id_local_origem: mae.id_local_destino,
											id_granja_destino: mae.id_granja_destino,
											id_local_destino: mae.id_local_destino,
											idade: 0,
											id_familia: mae.id_familia,
											id_raca: mae.id_raca,
											id_pelagem: mae.id_pelagem,
											possui_chifre: mae.possui_chifre,
											data_pesagem: new Date()
										});
									}
								}
							}}
							label='Mae'
							cols='12 6 6 4'
							placeholder='Selecione a Mãe'
							readOnly={readOnly} />
					</Row>

					<Row>
						<label>Local</label>
					</Row>
					{this.props.formularioValues.nascimento ? null : (
						<Row>
							<Field
								name='id_fornecedor_origem'
								component={Select}
								options={this.props.listaFornecedores}
								label='Fornecedor de Origem'
								cols='12 6 6 3'
								placeholder='Selecione o Fornecedor de Origem'
								readOnly={readOnly} />
						</Row>
					)}
					<Row>
						<Field
							name='id_granja_origem'
							component={Select}
							options={this.props.listaGranjas}
							label='Granja de Origem *'
							cols='12 6 6 3'
							placeholder='Selecione a Granja de Origem'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_local_origem'
							component={Select}
							options={this.props.listaLocais.filter(value => value.id_granja == this.props.formularioValues.id_granja_origem)}
							label='Local de Origem *'
							cols='12 6 6 3'
							placeholder='Selecione o Local'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_granja_destino'
							component={Select}
							options={this.props.listaGranjas}
							label='Granja de Destino *'
							cols='12 6 6 3'
							placeholder='Selecione a Granja de Destino'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_local_destino'
							component={Select}
							options={this.props.listaLocais.filter(value => value.id_granja == this.props.formularioValues.id_granja_destino)}
							label='Local de Destino *'
							cols='12 6 6 3'
							placeholder='Selecione o Local de Destino'
							readOnly={readOnly} />
					</Row>

					<Row>
						<hr />
						<label>Brinco</label>
					</Row>
					<Row>
						<Field
							name='id_brinco'
							component={Select}
							options={this.props.listaBrincos}
							label='Brinco *'
							cols='12 6 6 3'
							placeholder='Selecione o Brinco'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='numero_brinco'
							component={LabelAndInputNumber}
							label='Número do Brinco *'
							placeholder='Informe o Número do Brinco'
							cols='12 10 6 4'
							casas={0}
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='numero_brinco_padrao'
							component={LabelAndInput}
							label='Número do Brinco (Barras/Chip/QRCode))'
							placeholder='Informe o Número do Brinco'
							cols='12 10 6 4'
							readOnly={readOnly} />
					</Row>

					<Row>
						<hr />
						<label>Dados</label>
					</Row>
					<Row>
						<Field
							name='idade'
							component={LabelAndInputNumber}
							label='Idade'
							placeholder='Informe a Idade'
							casas={0}
							cols='12 10 6 4'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_familia'
							component={Select}
							options={this.props.listaFamilias}
							label='Espécie *'
							cols='12 6 6 3'
							placeholder='Selecione a Espécie'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_sexo'
							component={Select}
							options={this.props.listaSexos}
							label='Sexo *'
							cols='12 6 6 3'
							placeholder='Selecione o Sexo'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_raca'
							component={Select}
							options={this.props.listaRacas}
							label='Raça *'
							cols='12 6 6 3'
							placeholder='Selecione a Raça'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_pelagem'
							component={Select}
							options={this.props.listaPelagens}
							label='Pelagem *'
							cols='12 6 6 3'
							placeholder='Selecione a Pelagem'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='possui_chifre'
							component={LabelAndCheckbox}
							label='Pussui Chifre'
							placeholder='Informe se é um cliente'
							cols='12 6 6 6'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Grid cols='12 6 3 3'>
							<div className='form-group'>
								<label>{'Características'}</label>
								<input className='form-control'
									placeholder={'Informe a característica'}
									readOnly={readOnly}
									onChange={event => {
										if (event.target.value.includes(' ')) {
											let tags = event.target.value.split(' ');
											let caracteristicas = this.props.formularioValues.caracteristicas;
											tags.forEach(tag => {
												if (tag.trim()) {
													if (caracteristicas) {
														caracteristicas += `,${tag}`;
													} else {
														caracteristicas = `${tag}`;
													}
												}
											});
											event.target.value = '';

											this.props.initForm({
												...this.props.formularioValues,
												caracteristicas: caracteristicas
											}, 'animalForm');
										}
									}} />
								<br />
								{this.props.formularioValues.caracteristicas ?
									this.props.formularioValues.caracteristicas.split(',').map(caracteristica => {

										keyCaracteristica++;
										let key = caracteristica + keyCaracteristica;
										return (
											<span
												style={{ fontSize: 12, margin: 5 }}
												key={key}
												class='label label-success'
												onClick={() => {
													let caracteristicasAux = null;
													if (this.props.formularioValues.caracteristicas) {
														let posicao = 0;
														this.props.formularioValues.caracteristicas.split(',').map(itemCaracteristica => {
															posicao++;
															if (key != (itemCaracteristica + posicao)) {
																if (caracteristicasAux) {
																	caracteristicasAux += `,${itemCaracteristica}`;
																} else {
																	caracteristicasAux = `${itemCaracteristica}`;
																}
															}
														});
														this.props.initForm({
															...this.props.formularioValues,
															caracteristicas: caracteristicasAux
														}, 'animalForm');
													}
												}} >
												{caracteristica}
												<i className='fa fa-close'></i>
											</span>
										)
									})
								: null}
							</div>
						</Grid>
					</Row>

					<Row>
						<hr />
						<label>Peso</label>
					</Row>
					<Row>
						<Field
							name='data_pesagem'
							component={LabelAndInputDate}
							label='Data da pesagem'
							placeholder='Informe a data da pesagem'
							cols='8 4 3 2'
							readOnly={readOnly} />
	  				</Row>
					<Row>
						<Field
							name='peso_entrada'
							component={LabelAndInputNumber}
							label='Peso de Entrada'
							placeholder='Informe o Peso de Entrada'
							casas={2}
							cols='12 10 6 4'
							readOnly={readOnly} />
					</Row>

					<BoxButtonFooter>
						{this.props.excluir ? (
							<Button
								text='Excluir'
								submit type={'danger'}
								icon={'fa fa-trash'} />
						) : (
							<Button
								text='Salvar'
								submit
								type={'success'}
								icon={'fa fa-check'} />
						)}
						<Button
							text='Cancelar'
							type={'warning'}
							icon={'fa fa-chevron-left'}
							event={() => this.props.setModoTelaLote('lista')} />
					</BoxButtonFooter>
				</Form>
			</ContentCard>
        )
    }


}

AnimalForm = reduxForm({form: 'animalForm', destroyOnUnmount: false})(AnimalForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	registro: state.animal.registro,
	listaLotes: state.animal.listaLotes,
	listaMaes: state.animal.listaMaes,
	listaGranjas: state.animal.listaGranjas,
	listaFornecedores: state.animal.listaFornecedores,
	listaLocais: state.animal.listaLocais,
	listaFamilias: state.animal.listaFamilias,
	listaSexos: state.animal.listaSexos,
	listaRacas: state.animal.listaRacas,
	listaBrincos: state.animal.listaBrincos,
	listaPelagens: state.animal.listaPelagens,
	formularioValues: getFormValues('animalForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTelaLote,
	setModoTela, initForm, getListaGranja, getListaFornecedor, getListaLocal, getListaFamilia, getListaSexo, getListaRaca, getListaBrinco, getListaPelagem, getListaMae, getListaLote
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AnimalForm);
