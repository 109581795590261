import React from 'react'
import MenuItem from './menuItem'
import MenuTree from './menuTree'

export default props => (
    <ul className='sidebar-menu'>
        <MenuItem path='/' label='Dashboard' icon='dashboard' />
        <MenuTree label='Cadastro' icon='edit'>
            <MenuItem path='usuarioTipo' label='Tipo de Usuário' icon='vcard-o' />
            <MenuItem path='usuario' label='Usuário' icon='user-circle-o' />
			<MenuItem path='pessoa' label='Pessoa' icon='user' />
			<MenuItem path='brinco' label='Brinco' icon='tag' />
			<MenuItem path='raca' label='Raça' icon='certificate' />
			<MenuItem path='familia' label='Espécie' icon='sitemap' />
			<MenuItem path='pelagem' label='Pelagem' icon='adjust' />
			<MenuItem path='granja' label='Granja' icon='home' />
        </MenuTree>
		<MenuTree label='Estoque' icon='etsy'>
			<MenuItem path='produtoTipo' label='Tipo de Produto' icon='th-list' />
			<MenuItem path='produto' label='Produto' icon='product-hunt' />
			<MenuItem path='estoque' label='Movimento' icon='list-alt' />
			<MenuItem path='entrada' label='Entrada' icon='book' />
			<MenuItem path='estoqueAjuste' label='Ajuste' icon='sliders' />
			<MenuItem path='producaoRacao' label='Produção de Ração' icon='industry' />
			<MenuItem path='alimentacao' label='Alimentação' icon='cutlery' />
			<MenuItem path='animalVacina' label='Vacinação' icon='flask' />
        </MenuTree>
		<MenuItem path='lote' label='Lote' icon='th' />
		<MenuTree label='Movimento' icon='bars'>
			<MenuItem path='compraAnimal' label='Compra' icon='money' />
			<MenuItem path='venda' label='Venda' icon='dollar' />
			<MenuItem path='peso' label='Peso' icon='balance-scale' />
			<MenuItem path='transferencia' label='Transferência' icon='exchange' />
			<MenuItem path='baixa' label='Baixa' icon='minus-circle' />
        </MenuTree>
		<MenuTree label='Relatório' icon='area-chart'>
			<MenuItem path='relatorioInspetoriaVeterinaria' label='Inspetoria Veterinária' icon='institution' />
        </MenuTree>
    </ul>
)
