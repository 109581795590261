import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'GRANJA_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('granjaForm', registro),
		getListaCidade(registro && registro.id_estado ? registro.id_estado : 0)
    ];
}

export function getLista(id_empresa) {
    const request = axios.get(`${consts.API_URL}/granja?id_empresa=${id_empresa}`);
    return {
        type: 'GRANJA_LISTADO',
        payload: request
    };
}

export function getListaEstado() {
    const request = axios.get(`${consts.API_URL}/estado/listarselect`);
    return {
        type: 'GRANJA_ESTADO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaCidade(id_estado) {
    const request = axios.get(`${consts.API_URL}/cidade/listarselect?id_estado=${id_estado}`);
    return {
        type: 'GRANJA_CIDADE_SELECT_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/granja`, {
				...registro,
				id_empresa: getState().auth.usuario.id_empresa
			})
            .then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(getState().auth.usuario.id_empresa));
                dispatch(setModoTela('lista'));
            })
            .catch(e => {
                setErroAPI(e);
            });
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/granja?id=${registro.id}`)
            .then(resp => {
                setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(getState().auth.usuario.id_empresa));
                dispatch(setModoTela('lista'));
            })
            .catch(e => {
                setErroAPI(e);
            });
    };
}
