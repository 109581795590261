import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Button from '../common/button/button';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';

import { setModoTela, getListaUsuarioTipo } from './usuarioActions';

class UsuarioForm extends Component {

    componentWillMount() {
		this.props.getListaUsuarioTipo(this.props.sessao.id_empresa);
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<Row>
						<Field
							name='nome'
							component={LabelAndInput}
							label='Nome *' placeholder='Informe o Nome'
							cols='12 10 6 4'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_usuario_tipo'
							component={Select}
							onChange={this.props.changeValueUsuarioTipo}
							value={this.props.usuarioTipoSelecionado}
							options={this.props.listaUsuarioTipo}
							label='Tipo de Usuário *'
							cols='12 10 6 4'
							placeholder='Selecione o Tipo de Usuário'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='email'
							component={LabelAndInput}
							label='Email *'
							placeholder='Informe o Email'
							cols='12 10 6 4'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='cpf'
							component={LabelAndInput}
							label='CPF *'
							placeholder='Informe o CPF'
							row cols='12 10 6 4'
							readOnly={readOnly} />
					</Row>
					{this.props.registro.id ? null : (
					<Row>
						<Field
							name='senha'
							component={LabelAndInput}
							type="password"
							label='Senha *'
							placeholder='Informe a Senha'
							cols='12 10 6 4'
							readOnly={readOnly} />
					</Row>
					)}

					<BoxButtonFooter>
						{this.props.excluir ? (
							<Button
								text='Excluir'
								submit type={'danger'}
								icon={'fa fa-trash'} />
						) : (
							<Button
								text='Salvar'
								submit
								type={'success'}
								icon={'fa fa-check'} />
						)}
						<Button
							text='Cancelar'
							type={'warning'}
							icon={'fa fa-chevron-left'}
							event={() => this.props.setModoTela('lista')} />
					</BoxButtonFooter>
				</Form>
			</ContentCard>
        )
    }


}

UsuarioForm = reduxForm({form: 'usuarioForm', destroyOnUnmount: false})(UsuarioForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	listaUsuarioTipo: state.usuario.listaUsuarioTipo,
	registro: state.usuario.registro
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, getListaUsuarioTipo }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(UsuarioForm);
