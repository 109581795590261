import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';
import LabelAndInputDate from '../common/form/labelAndInputDate';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import { getDataAtual, getDataFormatada, getData, getDataBanco, getDataHoraBanco, getDataHoraFormatada } from '../utils/dataUtils';

import { setModoTela, initForm, getListaAnimal } from './baixaActions';

class BaixaForm extends Component {

	state = {
		filtroAnimal: ''
	};

    componentWillMount() {
		this.props.getListaAnimal(this.props.sessao.id_empresa);
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		let filtroAnimal = this.state.filtroAnimal ? this.state.filtroAnimal.toUpperCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&') : null;
		let listaAnimal = (this.props.listaAnimal || []).filter(animal => {

			if (filtroAnimal) {
				return (`${animal.numero_brinco}`).toUpperCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&').indexOf(filtroAnimal) != -1
					&& (`${animal.numero_lote}`).toUpperCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&').indexOf(filtroAnimal) != -1;
			} else {
				return null;
			}
		});

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<Row>
						{this.props.formularioValues.id_animal ? (
							<Grid cols='8 4 3 2'>
							<label>Animal</label>
								<div class="input-group">
									<input type='text' name='numero_brinco' value={this.props.formularioValues.numero_brinco} placeholder='Informe o animal' class='form-control' readOnly='readOnly' />
									<span class='input-group-btn'>
										<button type='button'
											class='btn btn-danger
											btn-flat'
											onClick={() => {
												this.props.initForm({
													...this.props.formularioValues,
													data_morte: getDataBanco(this.props.formularioValues.data_morte),
													id_animal: null,
													numero_brinco: null
												});
											}}>
											<i class='fa fa-times' aria-hidden='true'></i>
										</button>
									</span>
								</div>
								<br/>
							</Grid>
						) : (
							<Grid cols='12 8 6'>
								<div style={{ display: 'flex', flex: 2 }}>
									<div style={{ display: 'flex', flex: 2, flexDirection: 'row' }}>
										<div>
											<label>Animal</label>
											<input className='form-control'
												placeholder={'Informe o Animal'}
												value={this.state.filtroAnimal}
												onChange={(data => {
													this.setState({
														...this.state,
														filtroAnimal: data.target.value
													});
												})} />
										</div>
									</div>
								</div>
								{listaAnimal.length > 0 ? (
									<div style={{ display: 'flex', overflowX: 'auto', overflowY: 'auto', maxHeight: 150 }}>
										<table className='table table-bordered table-striped dataTable'>
											<THead>
												<Tr>
													<Th>Animal</Th>
													<Th>Lote</Th>
													<Th>Sexo</Th>
													<Th>Idade</Th>
												</Tr>
											</THead>
											<TBody>
												{listaAnimal.map(animal => {
													return (
														<Tr key={animal.id}
															onClick={() => {
																this.props.initForm({
																	...this.props.formularioValues,
																	data_morte: getDataBanco(this.props.formularioValues.data_morte),
																	id_animal: animal.id,
																	numero_brinco: animal.numero_brinco
																});
															}}>
															<Td>{animal.numero_brinco}</Td>
															<Td>{animal.numero_lote}</Td>
															<Td>{animal.nome_sexo}</Td>
															<Td>{parseInt(animal.idade_atual)}</Td>
														</Tr>
													);
												})}
											</TBody>
										</table>
									</div>
								) : null}

								<br />
							</Grid>
						)}
					</Row>
					<Row>
						<Field
							name='data_morte'
							component={LabelAndInputDate}
							label='Data da Morte *'
							placeholder='Informe a data'
							cols='8 4 3 2'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='causa'
							component={LabelAndInput}
							label='Causa *'
							placeholder='Informe a causa'
							cols='12 10 6 4'
							readOnly={readOnly} />
					</Row>
					<BoxButtonFooter>
						{this.props.excluir ? (
							<Button
								text='Excluir'
								submit type={'danger'}
								icon={'fa fa-trash'} />
						) : (
							<Button
								text='Salvar'
								submit
								type={'success'}
								icon={'fa fa-check'} />
						)}
						<Button
							text='Cancelar'
							type={'warning'}
							icon={'fa fa-chevron-left'}
							event={() => this.props.setModoTela('lista')} />
					</BoxButtonFooter>
				</Form>
			</ContentCard>
        )
    }


}

BaixaForm = reduxForm({form: 'baixaForm', destroyOnUnmount: false})(BaixaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	registro: state.baixa.registro,
	listaAnimal: state.baixa.listaAnimal,
	formularioValues: getFormValues('baixaForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, getListaAnimal }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(BaixaForm);
