import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';
import { toastr } from 'react-redux-toastr';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Button from '../common/button/button';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import Select from '../common/form/select';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import NumberFormat from 'react-number-format';

import { setModoTela, initForm, getListaProdutoTipo, getListaUnidadeMedida, getListaVacinaVia } from './produtoActions';

class ProdutoForm extends Component {

	state = {
		imagemBula: null,
		listaRacaoFormula: []
	};

    componentWillMount() {
		this.props.getListaProdutoTipo(this.props.sessao.id_empresa);
		this.props.getListaUnidadeMedida();
		this.props.getListaVacinaVia();
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		if (this.props.formularioValues && this.state.listaRacaoFormula.length != this.props.formularioValues.listaRacaoFormula.length) {
			this.setState({
				...this.state,
				listaRacaoFormula: this.props.formularioValues.listaRacaoFormula
			});
		}

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<Row>
						<Field
							name='nome'
							component={LabelAndInput}
							label='Nome *'
							placeholder='Informe o Nome'
							cols='12 10 6 4'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_produto_tipo'
							component={Select}
							options={this.props.listaProdutoTipo}
							label='Tipo do Produto'
							cols='12 12 8 4'
							placeholder='Selecione o Tipo do Produto'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_unidade_medida'
							component={Select}
							options={this.props.listaUnidadeMedida}
							label='Unidade de Medida'
							cols='12 12 8 4'
							placeholder='Selecione a Unidade de Medida'
							readOnly={readOnly} />
					</Row>
					{
						//Se for vacina abre os demais campos de controle da vacina
					}
					{this.props.formularioValues && this.props.listaProdutoTipo.filter(item => item.id == this.props.formularioValues.id_produto_tipo && item.vacina).length > 0 ? (
						<div>
							<Row>
								<Field
									name='principio_ativo'
									component={LabelAndInput}
									label='Princípio Ativo'
									placeholder='Informe o Princípio Ativo'
									cols='12 10 6 4'
									readOnly={readOnly} />
							</Row>
							<Row>
								<Field
									name='carencia'
									component={LabelAndInputNumber}
									label='Carência (dias) *'
									placeholder='Informe a Carência'
									casas={0}
									cols='12 6 4 2'
									readOnly={readOnly} />
							</Row>
							<Row>
								<Field
									name='indicacoes'
									component={LabelAndInput}
									label='Indicações'
									placeholder='Informe as Indicações'
									cols='12 12 8 6'
									readOnly={readOnly} />
							</Row>
							<Row>
								<Field
									name='dosagem'
									component={LabelAndInput}
									label='Dosagem'
									placeholder='Informe a Dosagem'
									cols='12 10 8 6'
									readOnly={readOnly} />
							</Row>
							<Row>
								<Field
									name='local_aplicacao'
									component={LabelAndInput}
									label='Local de Aplicação'
									placeholder='Informe o Local de Aplicação'
									cols='12 10 6 4'
									readOnly={readOnly} />
							</Row>
							<Row>
								<Field
									name='agulha'
									component={LabelAndInput}
									label='Agulha'
									placeholder='Informe a Agulha'
									cols='12 10 6 4'
									readOnly={readOnly} />
							</Row>
							<Row>
								<Field
									name='id_vacina_via'
									component={Select}
									options={this.props.listaVacinaVia}
									label='Via de Aplicação'
									cols='12 12 8 4'
									placeholder='Selecione a Via'
									readOnly={readOnly} />
							</Row>
							<Row>
								<Grid cols='12'>
									<div className='form-group'>
										<label>Bula</label>
										<Field
											name='bula'
											type='file'
											accept='image/jpg/jpeg/png'
											component={({ input, type, meta }) => {
												const { mimeType } = this.props;
									  			return (
									  				<div>
									  					<input
									  						name={input.name}
									  						type={type}
									  						accept={mimeType}
									  						onChange={event => {
									  							event.preventDefault();
									  							let reader = new FileReader();
									  							let file = event.target.files[0];

									  							if (file.size > 104857600) {
									  								toastr.error('Erro', 'Imagem não pode ultrapassar 100 MB.');
									  								return;
									  							}
									  							if (!file.type.includes('jpeg') && !file.type.includes('jpg') && !file.type.includes('png')) {
									  								toastr.error('Erro', 'Selecione apenas JPEG e PNG.');
									  								return;
									  							}

									  							reader.onloadend = () => {
									  								input.onChange(reader.result);
																	this.setState({ ...this.state, imagemBula: reader.result })
									  							}

									  							reader.readAsDataURL(file);
									  						}} />
									  				</div>
									  			)
											}}
					                    />
										{this.state.imagemBula ? <img style={{ marginTop:10, height: 300 }} src={this.state.imagemBula} />
											: (this.props.registro && this.props.registro.bula ? <img style={{ marginTop:10, height: 300 }} src={this.props.registro.bula} /> : null)}
									</div>
								</Grid>
							</Row>
						</div>
					) : null }

					{
						//Se for ração abre os demais adiciona os itens da fórmula
					}
					{this.props.formularioValues && this.props.listaProdutoTipo.filter(item => item.id == this.props.formularioValues.id_produto_tipo && item.racao).length > 0 ? (
						<div>
							<Row>
								<Grid cols='12'>
									<label>Selecione os produtos que compõe a ração</label>
								</Grid>
								<Grid cols='4' style={{ marginBottom: 8 }}>
									<select
									 	style={{ marginBottom: 8 }}
										className='form-control'
										onChange={data => {

											if (data.target.value && data.target.value > 0) {

												let listaRacaoFormula = this.props.formularioValues.listaRacaoFormula;
												let materiaPrima = this.props.listaMateriaPrima.filter(item => item.id == data.target.value)[0];

												listaRacaoFormula.push({
													id: null,
													id_racao: this.props.formularioValues && this.props.formularioValues.id ? this.props.formularioValues.id : null,
													id_materia_prima: materiaPrima.id,
													nome_materia_prima: materiaPrima.nome,
													quantidade: 0,
													ativo: true
												});

												this.props.initForm({
													...this.props.formularioValues,
													listaRacaoFormula: listaRacaoFormula
												}, 'produtoForm');

												this.setState({
													...this.state,
													listaRacaoFormula: listaRacaoFormula
												});

												data.target.value = 0;
											}

										}} >
										<option
											key={null}
											value={0}>{'Selecione a Matéria Prima'}</option>
										{this.props.listaMateriaPrima.filter(opt => !opt.vacina).map(opt => { //Apenas produtos, se, Vacina e ração

											return (
												<option
													key={opt.id}
													value={opt.id}>{opt.nome}</option>
											);
										})}
									</select>
								</Grid>
								<Grid cols='12'>
									<Table>
										<THead>
											<Tr>
												<Th>Matéria Prima</Th>
												<Th alignRight width={200}>Quantidade</Th>
												<Th alignCenter width={70}>Ações</Th>
											</Tr>
										</THead>
										<TBody>
											{this.state.listaRacaoFormula.filter(item => item.ativo).map(item => (
												<Tr key={item.id}>
													<Td>{item.nome_materia_prima}</Td>
													<Td alignCenter>
														<NumberFormat
															style={{ textAlign: 'right' }}
															className='form-control'
															placeholder={'0,00'}
															decimalSeparator={","}
															decimalScale={'2'}
															fixedDecimalScale={true}
															thousandSeparator={''}
															value={item.quantidade ? String(item.quantidade).replace('.', ',') : '0,00'}
															onChange={data => {

																let listaRacaoFormula = this.props.formularioValues.listaRacaoFormula.map(racaoItem => {
																	if (racaoItem.id == item.id && racaoItem.id_materia_prima == item.id_materia_prima) {
																		return {
																			...racaoItem,
																			quantidade: data.target.value.replace(',', '.')
																		};
																	} else {
																		return racaoItem;
																	}
																});

																this.props.initForm({
																	...this.props.formularioValues,
																	listaRacaoFormula: listaRacaoFormula
																});

																this.setState({
																	...this.state,
																	listaRacaoFormula: listaRacaoFormula
																});

															}} />
													</Td>
													<Td alignCenter>
														<ButtonTable
															type={'danger'}
															icon={'fa fa-trash-o'}
															event={() => {

																let listaRacaoFormula = this.props.formularioValues.listaRacaoFormula.map(racaoItem => {
																	if (racaoItem.id == item.id && racaoItem.id_materia_prima == item.id_materia_prima) {
																		return {
																			...racaoItem,
																			ativo: false
																		};
																	} else {
																		return racaoItem;
																	}
																});

																this.props.initForm({
																	...this.props.formularioValues,
																	listaRacaoFormula: listaRacaoFormula
																});

																this.setState({
																	...this.state,
																	listaRacaoFormula: listaRacaoFormula
																});

															}} />
													</Td>
												</Tr>
											))}
										</TBody>
									</Table>
								</Grid>
							</Row>
						</div>
					) : null}
					<BoxButtonFooter>
						{this.props.excluir ? (
							<Button
								text='Excluir'
								submit type={'danger'}
								icon={'fa fa-trash'} />
						) : (
							<Button
								text='Salvar'
								submit
								type={'success'}
								icon={'fa fa-check'} />
						)}
						<Button
							text='Cancelar'
							type={'warning'}
							icon={'fa fa-chevron-left'}
							event={() => this.props.setModoTela('lista')} />
					</BoxButtonFooter>
				</Form>
			</ContentCard>
        )
    }


}

ProdutoForm = reduxForm({form: 'produtoForm', destroyOnUnmount: false})(ProdutoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	registro: state.produto.registro,
	listaMateriaPrima: state.produto.lista,
	listaProdutoTipo: state.produto.listaProdutoTipo,
	listaUnidadeMedida: state.produto.listaUnidadeMedida,
	listaVacinaVia: state.produto.listaVacinaVia,
	listaRacaoFormula: state.produto.listaRacaoFormula,
	formularioValues: getFormValues('produtoForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, getListaProdutoTipo, getListaUnidadeMedida, getListaVacinaVia }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ProdutoForm);
