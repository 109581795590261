import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import { getDataAtual, getDataFormatada, getData, getDataHoraBanco, getDataHoraFormatada } from '../utils/dataUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'ALIMENTACAO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = { datahora_alimentacao: getDataAtual() }) {
    return [
        initialize('alimentacaoForm', {
			...registro,
			datahora_alimentacao: getDataHoraFormatada(registro.datahora_alimentacao, 'DD/MM/YYYY HH:mm')
		})
    ];
}

export function getLista(id_empresa) {
    const request = axios.get(`${consts.API_URL}/alimentacao?id_empresa=${id_empresa}`);
    return {
        type: 'ALIMENTACAO_LISTADO',
        payload: request
    };
}

export function getListaGranja(id_empresa) {
    const request = axios.get(`${consts.API_URL}/granja/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'ALIMENTACAO_GRANJA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaLote(id_empresa) {
    const request = axios.get(`${consts.API_URL}/lote/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'ALIMENTACAO_LOTE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaAnimal(id_empresa) {
    const request = axios.get(`${consts.API_URL}/animal/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'ALIMENTACAO_ANIMAL_SELECT_LISTADO',
        payload: request
    };
}

export function getListaProduto(id_empresa) {
    const request = axios.get(`${consts.API_URL}/produto/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'ALIMENTACAO_PRODUTO_SELECT_LISTADO',
        payload: request
    };
}

export function salvar(registro) {

    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/alimentacao`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa,
			datahora_alimentacao: getDataHoraBanco(registro.datahora_alimentacao),
			id_granja: registro.id_granja == '0' ? null : registro.id_granja,
			id_lote: registro.id_lote == '0' ? null : registro.id_lote,
			id_animal: registro.id_animal == '0' ? null : registro.id_animal,
			id_produto: registro.id_produto == '0' ? null : registro.id_produto
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(getState().auth.usuario.id_empresa));
            dispatch(setModoTela('lista'));
        }).catch(e => {
            setErroAPI(e);
        });
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/alimentacao?id=${registro.id}`)
            .then(resp => {
                setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(getState().auth.usuario.id_empresa));
                dispatch(setModoTela('lista'));
            })
            .catch(e => {
                setErroAPI(e);
            });
    };
}
