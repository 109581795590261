import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { reducer as toastrReducer } from 'react-redux-toastr';

import AuthReducer from '../auth/authReducer';
import DashboardReducer from '../dashboard/dashboardReducer';
import TabReducer from '../common/tab/tabReducer';
import UsuarioTipoReducer from '../usuarioTipo/usuarioTipoReducer';
import UsuarioReducer from '../usuario/usuarioReducer';
import PessoaReducer from '../pessoa/pessoaReducer';
import BrincoReducer from '../brinco/brincoReducer';
import RacaReducer from '../raca/racaReducer';
import FamiliaReducer from '../familia/familiaReducer';
import PelagemReducer from '../pelagem/pelagemReducer';
import GranjaReducer from '../granja/granjaReducer';
import LocalReducer from '../granja/local/localReducer';
import ProdutoReducer from '../produto/produtoReducer';
import ProdutoTipoReducer from '../produtoTipo/produtoTipoReducer';
import PrevisaoPesoReducer from '../previsaoPeso/previsaoPesoReducer';
import LoteReducer from '../lote/loteReducer';
import AnimalReducer from '../lote/animal/animalReducer';
import EntradaReducer from '../entrada/entradaReducer';
import EstoqueAjusteReducer from '../estoqueAjuste/estoqueAjusteReducer';
import EstoqueReducer from '../estoque/estoqueReducer';
import AnimalVacinaReducer from '../animalVacina/animalVacinaReducer';
import AlimentacaoReducer from '../alimentacao/alimentacaoReducer';
import ProducaoRacaoReducer from '../producaoRacao/producaoRacaoReducer';
import CompraAnimalReducer from '../compraAnimal/compraAnimalReducer';
import VendaReducer from '../venda/vendaReducer';
import PesagemReducer from '../pesagem/pesagemReducer';
import PesoReducer from '../peso/pesoReducer';
import TransferenciaReducer from '../transferencia/transferenciaReducer';
import BaixaReducer from '../baixa/baixaReducer';

import RelatorioInspetoriaVeterinariaReducer from '../relatorioInspetoriaVeterinaria/relatorioInspetoriaVeterinariaReducer';

const rootReducer = combineReducers({
	auth: AuthReducer,
    dashboard: DashboardReducer,
    usuarioTipo: UsuarioTipoReducer,
    usuario: UsuarioReducer,
	pessoa: PessoaReducer,
	brinco: BrincoReducer,
	raca: RacaReducer,
	familia: FamiliaReducer,
	pelagem: PelagemReducer,
	granja: GranjaReducer,
	local: LocalReducer,
	produto: ProdutoReducer,
	produtoTipo: ProdutoTipoReducer,
	previsaoPeso: PrevisaoPesoReducer,
	lote: LoteReducer,
	animal: AnimalReducer,
	entrada: EntradaReducer,
	estoqueAjuste: EstoqueAjusteReducer,
	estoque: EstoqueReducer,
	animalVacina: AnimalVacinaReducer,
	alimentacao: AlimentacaoReducer,
	producaoRacao: ProducaoRacaoReducer,
	venda: VendaReducer,
	compraAnimal: CompraAnimalReducer,
	peso: PesoReducer,
	transferencia: TransferenciaReducer,
	baixa: BaixaReducer,
	relatorioInspetoriaVeterinaria: RelatorioInspetoriaVeterinariaReducer,
    tab: TabReducer,
    form: formReducer,
    toastr: toastrReducer
});

export default rootReducer;
