import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';
import { toastr } from 'react-redux-toastr';
import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';

import { setModoTela, initForm, getListaRacaTipo } from './racaActions';

class RacaForm extends Component {

	state = { imagem: null };

    componentWillMount() {
		this.props.getListaRacaTipo();
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<Row>
						<Field
							name='nome'
							component={LabelAndInput}
							label='Nome *'
							placeholder='Informe o Nome'
							cols='12 10 6 4'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Grid>
							<label>Tipo *</label>
							<div>
								{this.props.listaRacaTipo.map(item => {

									return (
										<label
											style={{
												marginRight: 4,
												marginBottom: 4,
												paddingLeft: 8,
												paddingRight: 8,
												borderRadius: 8,
												fontWeight: 'normal',
												backgroundColor: (this.state.idRacaTipo || this.props.formularioValues.raca_tipo || []).filter(itemSelecionado => itemSelecionado == item.id).length > 0 ? '#00a65a' : '#d2d6de',
												color: (this.props.formularioValues.raca_tipo || []).filter(itemSelecionado => itemSelecionado == item.id).length > 0 ? '#ffffff' : '#000000'
											}}
											onClick={(event) => {
												let idRacaTipo = this.props.formularioValues.raca_tipo || [];
												if (idRacaTipo.filter(itemSelecionado => itemSelecionado == item.id).length > 0) {
													let idRacaTipoAux = [];
													idRacaTipo.forEach(itemSelecionado => {
														if (itemSelecionado != item.id) {
															idRacaTipoAux.push(itemSelecionado);
														}
													});
													idRacaTipo = idRacaTipoAux;
												} else {
													idRacaTipo.push(item.id);
												}

												this.props.initForm({
													...this.props.formularioValues,
													raca_tipo: idRacaTipo
												}, 'racaForm');

												this.setState({
													...this.state,
													idRacaTipo: idRacaTipo
												})
											}}>
											{item.valor}
										</label>
									);
								})}
							</div>
						</Grid>
					</Row>
					<Row>
						<Grid cols='12'>
							<div className='form-group'>
								<label>Imagem</label>
								<Field
									name='imagem'
									type='file'
									accept='image/jpg/jpeg/png'
									component={({ input, type, meta }) => {
										const { mimeType } = this.props;
							  			return (
							  				<div>
							  					<input
							  						name={input.name}
							  						type={type}
							  						accept={mimeType}
							  						onChange={event => {
							  							event.preventDefault();
							  							let reader = new FileReader();
							  							let file = event.target.files[0];

							  							if (file.size > 104857600) {
							  								toastr.error('Erro', 'Imagem não pode ultrapassar 100 MB.');
							  								return;
							  							}
							  							if (!file.type.includes('jpeg') && !file.type.includes('jpg') && !file.type.includes('png')) {
							  								toastr.error('Erro', 'Selecione apenas JPEG e PNG.');
							  								return;
							  							}

							  							reader.onloadend = () => {
							  								input.onChange(reader.result);
															this.setState({ ...this.state, imagem: reader.result })
							  							}

							  							reader.readAsDataURL(file);
							  						}} />
							  				</div>
							  			)
									}}
			                    />
								{this.state.imagem ? <img style={{ marginTop: 10, height: 300 }} src={this.state.imagem} />
									: (this.props.registro && this.props.registro.imagem ? <img style={{ marginTop:10, height: 300 }} src={this.props.registro.imagem} /> : null)}
							</div>
						</Grid>
					</Row>
					<BoxButtonFooter>
						{this.props.excluir ? (
							<Button
								text='Excluir'
								submit type={'danger'}
								icon={'fa fa-trash'} />
						) : (
							<Button
								text='Salvar'
								submit
								type={'success'}
								icon={'fa fa-check'} />
						)}
						<Button
							text='Cancelar'
							type={'warning'}
							icon={'fa fa-chevron-left'}
							event={() => this.props.setModoTela('lista')} />
					</BoxButtonFooter>
				</Form>
			</ContentCard>
        )
    }


}

RacaForm = reduxForm({form: 'racaForm', destroyOnUnmount: false})(RacaForm);
const mapStateToProps = state => ({
	listaRacaTipo: state.raca.listaRacaTipo,
	registro: state.raca.registro,
	formularioValues: getFormValues('racaForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, getListaRacaTipo }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(RacaForm);
