import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import Button from '../common/button/button';
import Form from './loteForm';
import AnimalForm from './animal/animalForm';

import { setModoTela, initForm, setFiltro, salvar, excluir, getLista, getListaAnimaisFiltro, getListaGranja, transferenciaLote } from './loteActions';
import { setModoTela as setModoTelaAnimal, initForm as initFormAnimal, salvar as salvarAnimal, excluir as excluirAnimal, getListaUltimoIncluidoLote } from './animal/animalActions';

class Lote extends Component {

	state = {
		id_lote_selecionado: null,
		transferirLote: false,
		listaAnimaisSelecionados: []
	};

    componentWillMount() {
        this.props.getLista(this.props.sessao.id_empresa);
		this.props.getListaAnimaisFiltro(this.props.sessao.id_empresa, this.props.filtro);
		this.props.getListaGranja(this.props.sessao.id_empresa);
		this.props.getListaUltimoIncluidoLote(this.props.sessao.id_empresa);
    }

    render() {
        return (
            <div>
				{this.props.modoTela == 'lista' || this.props.modoTela == 'cadastro' || this.props.modoTela == 'exclusao' ? (
					<ContentHeader title='Lote' small='Cadastro' />
				) : (
					<ContentHeader title='Animal' small='Cadastro' />
				)}
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'exclusao' ? (
						<Form excluir onSubmit={this.props.excluir} />
					) : null}
					{this.props.modoTela == 'cadastroAnimal' ? (
						<AnimalForm onSubmit={this.props.salvarAnimal} />
					) : null}
					{this.props.modoTela == 'exclusaoAnimal' ? (
						<AnimalForm excluir onSubmit={this.props.excluirAnimal} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {

		let listaAnimal = this.props.listaAnimal.filter(itemAnimal => {
			let retornarAnimal = true;
			if (this.props.filtro.id_granja && itemAnimal.id_granja_destino != this.props.filtro.id_granja) {
				retornarAnimal = false;
			}
			if (this.props.filtro.brinco && itemAnimal.numero_brinco != this.props.filtro.brinco) {
				retornarAnimal = false;
			}
			if (this.props.filtro.lote && itemAnimal.numero_lote != this.props.filtro.lote) {
				retornarAnimal = false;
			}
			if (this.props.filtro.status == 1 && (itemAnimal.vendido || itemAnimal.baixado)) {
				retornarAnimal = false;
			}
			if (this.props.filtro.status == 2 && !itemAnimal.vendido) {
				retornarAnimal = false;
			}
			if (this.props.filtro.status == 3 && !itemAnimal.baixado) {
				retornarAnimal = false;
			}
			return retornarAnimal;
		});

		return (
			<ContentCard>
				<BoxButtonHeader>
					{!this.state.transferirLote ? (
						<div>
							<Button
								text='Adicionar Lote'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {
									this.props.setModoTela('cadastro', {});
									this.props.initForm();
								}} />
							<Button
								text='Adicionar Animal'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {

									let registro = {
									}

									if (this.props.listaUltimoIncluidoLotes && this.props.listaUltimoIncluidoLotes.length > 0) {
										let animalBase = this.props.listaUltimoIncluidoLotes[this.props.listaUltimoIncluidoLotes.length-1];
										if (animalBase) {
											registro = {
												id_lote: animalBase.id_lote,
												id_brinco: animalBase.id_brinco,
												numero_brinco: parseInt(animalBase.numero_brinco) + 1,
												id_fornecedor_origem: animalBase.id_fornecedor_origem,
												id_granja_origem: animalBase.id_granja_origem,
												id_local_origem: animalBase.id_local_origem,
												id_granja_destino: animalBase.id_granja_destino,
												id_local_destino: animalBase.id_local_destino
											}
										}
									}

									this.props.setModoTela('cadastroAnimal', registro);
									this.props.initFormAnimal(registro);

								}} />
							<Button
								text='Adicionar Nascimento'
								type={'success'}
								icon={'fa fa-plus'}
								event={() => {

									let registro = {
										nascimento: true
									}

									if (this.props.listaUltimoIncluidoLotes && this.props.listaUltimoIncluidoLotes.length > 0) {
										let animalBase = this.props.listaUltimoIncluidoLotes[this.props.listaUltimoIncluidoLotes.length-1];
										if (animalBase) {
											registro = {
												nascimento: true,
												id_lote: animalBase.id_lote,
												id_brinco: animalBase.id_brinco,
												numero_brinco: parseInt(animalBase.numero_brinco) + 1,
												id_granja_origem: animalBase.id_granja_origem,
												id_local_origem: animalBase.id_local_origem,
												id_granja_destino: animalBase.id_granja_destino,
												id_local_destino: animalBase.id_local_destino
											}
										}
									}

									this.props.setModoTela('cadastroAnimal', registro);
									this.props.initFormAnimal(registro);


								}} />
							<Button
								text='Tranferir de Lote'
								type={'primary'}
								icon={'fa fa-exchange'}
								event={() => {
									this.setState({
										...this.state,
										transferirLote: true,
										id_lote: null,
										listaAnimaisSelecionados: []
									})
								}} />
						</div>
					) : (
						<div class='input-group col-xs-12 col-sm-12 col-md-8 col-lg-6'>
							<select className='form-control'
								value={this.state.id_lote}
								onChange={data => {
									this.setState({
										...this.state,
										id_lote: data.target.value != 0 ? data.target.value : null
									});
								}}>
								<option value='0'>Selecione o lote que deseja transferir</option>
								{this.props.lista.map(opt => (
									<option key={opt.id} value={opt.id}>{opt.numero}</option>
								))}
							</select>
							<span class='input-group-btn'>
							<Button
								text='Transferir'
								type={'success'}
								icon={'fa fa-check'}
								event={() => {
									this.props.transferenciaLote(this.state.id_lote, this.state.listaAnimaisSelecionados, () => {
										this.setState({
											...this.state,
											transferirLote: false,
											id_lote: null,
											listaAnimaisSelecionados: []
										})
									});
								}} />
							<Button
								text='Cancelar'
								type={'danger'}
								icon={'fa fa-ban'}
								event={() => {
									this.setState({
										...this.state,
										transferirLote: false,
										id_lote: null,
										listaAnimaisSelecionados: []
									})
								}} />
							</span>
						</div>
					)}
				</BoxButtonHeader>
				<Row>
					<Grid cols='12 3 2'>
						<label>Status</label>
						<select className='form-control'
							value={this.props.filtro.status}
						 	onChange={data => {
								let filtro = {
									...this.props.filtro,
									status: data.target.value != 0 ? data.target.value : null
								};
								this.props.setFiltro(filtro);
								this.props.getListaAnimaisFiltro(this.props.sessao.id_empresa, filtro);
							}}>
			                <option value={0}>Todos</option>
							<option value={1}>Aberto</option>
							<option value={2}>Vendido</option>
							<option value={3}>Baixado</option>
						</select>
					</Grid>
					<Grid cols='12 9 4'>
						<label>Granja</label>
						<select className='form-control'
							value={this.props.filtro.id_granja}
						 	onChange={data => {
								let filtro = {
									...this.props.filtro,
									id_granja: data.target.value != 0 ? data.target.value : null
								};
								this.props.setFiltro(filtro);
								this.props.getListaAnimaisFiltro(this.props.sessao.id_empresa, filtro);
							}}>
			                <option value='0'>Todas</option>
			                {this.props.listaGranjas.map(opt => (
								<option key={opt.id} value={opt.id}>{opt.valor}</option>
							))}
						</select>
					</Grid>
					<Grid cols='6 6 3'>
						<label>Lote</label>
						<input className='form-control'
							placeholder={'Informe o Lote'}
							value={this.props.filtro.lote}
							onChange={(data => {
								this.props.setFiltro({
									...this.props.filtro,
									lote: data.target.value
								});
							})} />
					</Grid>
					<Grid cols='6 6 3'>
						<label>Brinco</label>
						<input className='form-control'
							placeholder={'Informe o Brinco'}
							value={this.props.filtro.brinco}
							onChange={(data => {
								this.props.setFiltro({
									...this.props.filtro,
									brinco: data.target.value
								});
							})} />
					</Grid>
				</Row>
				<br/>
				<Table>
					<THead>
						<Tr>
							<Th>Lote</Th>
							<Th>Número do Brinco</Th>
							<Th>Brinco</Th>
							<Th>Mãe</Th>
							<Th>Granja</Th>
							<Th>Local</Th>
							<Th>Família</Th>
							<Th>Sexo</Th>
							<Th>Raca</Th>
							<Th>Pelagem</Th>
							<Th alignCenter>Idade</Th>
							<Th alignCenter>Peso</Th>
							<Th alignCenter acoes>Ações</Th>
						</Tr>
					</THead>
					<TBody>
						{listaAnimal.map(animal => {
							return (
								<Tr key={animal.id}>
									{this.state.transferirLote ? (
										<Td alignCenter style={{ width: 40 }}>
											<input type='checkbox'
												checked={this.state.listaAnimaisSelecionados.filter(animalSelecionado => animalSelecionado.id == animal.id).length > 0}
												onChange={() => {
													if (this.state.listaAnimaisSelecionados.filter(animalSelecionado => animalSelecionado.id == animal.id).length > 0) {
														this.setState({
															...this.state,
															listaAnimaisSelecionados: this.state.listaAnimaisSelecionados.filter(animalSelecionado => animalSelecionado.id != animal.id)
														});
													} else {
														let listaAux = this.state.listaAnimaisSelecionados;
														listaAux.push(animal);

														this.setState({
															...this.state,
															listaAnimaisSelecionados: listaAux
														});
													}
												}} />
										</Td>
									) : null}
									<Td>{animal.numero_lote}</Td>
									<Td>{animal.numero_brinco}</Td>
									<Td>{animal.brinco}</Td>
									<Td>{animal.mae}</Td>
									<Td>{animal.nome_granja}</Td>
									<Td>{animal.nome_local}</Td>
									<Td>{animal.nome_familia}</Td>
									<Td>{animal.nome_sexo}</Td>
									<Td>{animal.nome_raca}</Td>
									<Td>{animal.nome_pelagem}</Td>
									<Td alignCenter>{parseInt(animal.idade)} - {parseInt(animal.idade_atual)}</Td>
									<Td alignCenter>{parseInt(animal.peso_entrada)} - {animal.peso_atual ? parseInt(animal.peso_atual) : parseInt(animal.peso_entrada)}</Td>
									<Td alignCenter>
										<ButtonTable
											type={'warning'}
											icon={'fa fa-pencil'}
											event={() => {
												this.props.setModoTela('cadastroAnimal', {...animal, peso_entrada: animal.peso_entrada.replace('.', ',') });
												this.props.initFormAnimal({ ...animal, peso_entrada: animal.peso_entrada.replace('.', ',') });
											}} />
										<ButtonTable
											type={'danger'}
											icon={'fa fa-trash-o'}
											event={() => {
												this.props.setModoTela('exclusaoAnimal', { ...animal, peso_entrada: animal.peso_entrada.replace('.', ',') });
												this.props.initFormAnimal({ ...animal, peso_entrada: animal.peso_entrada.replace('.', ',') });
											}} />
									</Td>
								</Tr>
							);
						})}
					</TBody>
				</Table>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.lote.modoTela,
	lista: state.lote.lista,
	listaAnimal: state.lote.listaAnimal,
	listaGranjas: state.lote.listaGranjas,
	listaUltimoIncluidoLotes: state.animal.listaUltimoIncluidoLotes,
	filtro: state.lote.filtro
});
const mapDispatchToProps = dispatch => bindActionCreators({
	setModoTela, initForm, setFiltro, salvar, excluir, getLista, getListaAnimaisFiltro, getListaGranja, transferenciaLote,
	setModoTelaAnimal, initFormAnimal, salvarAnimal, excluirAnimal, getListaUltimoIncluidoLote
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Lote);
