import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Button from '../common/button/button';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';

import { setModoTela, getListaGranja, getListaFornecedor, getListaProduto } from './entradaActions';

class EntradaForm extends Component {

    componentWillMount() {
		this.props.getListaGranja(this.props.sessao.id_empresa);
		this.props.getListaFornecedor(this.props.sessao.id_empresa);
		this.props.getListaProduto(this.props.sessao.id_empresa);
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<Row>
						<Field
							name='id_granja'
							component={Select}
							options={this.props.listaGranja}
							label='Granja *'
							cols='12 12 8 4'
							placeholder='Selecione a Granja'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_fornecedor'
							component={Select}
							options={this.props.listaFornecedor}
							label='Fornecedor *'
							cols='12 12 8 4'
							placeholder='Selecione o Fornecedor'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_produto'
							component={Select}
							options={this.props.listaProduto}
							label='Produto *'
							cols='12 12 8 4'
							placeholder='Selecione o Produto'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='datahora_entrada'
							component={LabelAndInputDateTime}
							label='Data da Entrada'
							placeholder='Informe a data'
							cols='8 4 3 2'
							readOnly={readOnly} />
	  				</Row>
					<Row>
						<Field
							name='quantidade'
							component={LabelAndInputNumber}
							label='Quantidade *'
							placeholder='Informe a Quantidade'
							cols='12 10 4 2'
							casas={2}
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='valor'
							component={LabelAndInputNumber}
							label='Valor *'
							placeholder='Informe o Valor'
							cols='12 10 4 2'
							casas={2}
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='numero_nfe'
							component={LabelAndInput}
							label='Número da NF-e'
							placeholder='Informe o Número da NF-e'
							cols='12 12 8 4'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='observacao'
							component={LabelAndInput}
							label='Observação'
							placeholder='Informe a observação'
							cols='12 12 8 4'
							readOnly={readOnly} />
					</Row>

					<BoxButtonFooter>
						{this.props.excluir ? (
							<Button
								text='Excluir'
								submit type={'danger'}
								icon={'fa fa-trash'} />
						) : (
							<Button
								text='Salvar'
								submit
								type={'success'}
								icon={'fa fa-check'} />
						)}
						<Button
							text='Cancelar'
							type={'warning'}
							icon={'fa fa-chevron-left'}
							event={() => this.props.setModoTela('lista')} />
					</BoxButtonFooter>
				</Form>
			</ContentCard>
        )
    }


}

EntradaForm = reduxForm({form: 'entradaForm', destroyOnUnmount: false})(EntradaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	listaGranja: state.entrada.listaGranja,
	listaFornecedor: state.entrada.listaFornecedor,
	listaProduto: state.entrada.listaProduto,
	registro: state.entrada.registro
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, getListaGranja, getListaFornecedor, getListaProduto }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(EntradaForm);
