const INITIAL_STATE = { registro: {}, modoTela: 'lista', lista: [], listaLotes: [], listaMaes: [], listaGranjas: [], listaFornecedores: [], listaLocais: [],
						listaFamilias: [], listaSexos: [], listaRacas: [], listaBrincos: [], listaPelagens: [], listaUltimoIncluidoLotes: [] };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'ANIMAL_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};
        case 'ANIMAL_LISTADO':
            return { ...state, modoTela: 'lista', lista: action.payload.data };
		case 'ANIMAL_LOTE_SELECT_LISTADO':
			return { ...state, listaLotes: action.payload.data };
		case 'ANIMAL_ULTIMO_INCLUIDO_LOTE_LISTADO':
			return { ...state, listaUltimoIncluidoLotes: action.payload.data };
		case 'ANIMAL_MAE_SELECT_LISTADO':
			return { ...state, listaMaes: action.payload.data };
		case 'ANIMAL_GRANJA_SELECT_LISTADO':
			return { ...state, listaGranjas: action.payload.data };
		case 'ANIMAL_FORNECEDOR_SELECT_LISTADO':
			return { ...state, listaFornecedores: action.payload.data };
		case 'ANIMAL_LOCAL_SELECT_LISTADO':
			return { ...state, listaLocais: action.payload.data };
		case 'ANIMAL_FAMILIA_SELECT_LISTADO':
			return { ...state, listaFamilias: action.payload.data };
		case 'ANIMAL_SEXO_SELECT_LISTADO':
			return { ...state, listaSexos: action.payload.data };
		case 'ANIMAL_RACA_SELECT_LISTADO':
			return { ...state, listaRacas: action.payload.data };
		case 'ANIMAL_BRINCO_SELECT_LISTADO':
			return { ...state, listaBrincos: action.payload.data };
		case 'ANIMAL_PELAGEM_SELECT_LISTADO':
			return { ...state, listaPelagens: action.payload.data };
        default:
            return state;
    }
}
