const INITIAL_STATE = { registro: {}, modoTela: 'lista', lista: [], listaProdutoTipo: [], listaUnidadeMedida: [], listaVacinaVia: [] };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'VACINA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};
        case 'VACINA_LISTADO':
            return { ...state, modoTela: 'lista', lista: action.payload.data };
		case 'VACINA_PRODUTO_TIPO_SELECT_LISTADO':
            return { ...state, listaProdutoTipo: action.payload.data };
		case 'VACINA_UNIDADE_MEDIDA_SELECT_LISTADO':
            return { ...state, listaUnidadeMedida: action.payload.data };
		case 'VACINA_VIA_SELECT_LISTADO':
            return { ...state, listaVacinaVia: action.payload.data };
        default:
            return state;
    }
}
