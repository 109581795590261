import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';

import { setModoTela, getListaGranja, getListaRacao, getListaMateriaPrima, getListaProducaoRacaoItem } from './producaoRacaoActions';

class ProducaoRacaoForm extends Component {

    componentWillMount() {
		this.props.getListaGranja(this.props.sessao.id_empresa);
		this.props.getListaRacao(this.props.sessao.id_empresa);
		this.props.getListaProducaoRacaoItem(this.props.formularioValues.id, this.props.sessao.id_empresa);
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<Row>
						<Field
							name='id_granja'
							component={Select}
							options={this.props.listaGranja}
							label='Granja *'
							cols='12 12 8 4'
							placeholder='Selecione a Granja'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_racao'
							component={Select}
							options={this.props.listaRacao}
							label='Ração *'
							cols='12 12 8 4'
							placeholder='Selecione a Ração'
							readOnly={readOnly}
							onChange={data => {
								this.props.getListaMateriaPrima(data.target.value);
							}} />
					</Row>
					<Row>
						<Field
							name='datahora_producao'
							component={LabelAndInputDateTime}
							label='Data da produção'
							placeholder='Informe a Data e Hora'
							cols='8 4 3 2'
							readOnly={readOnly} />
	  				</Row>
					<Row>
						<Field
							name='quantidade'
							component={LabelAndInputNumber}
							label='Quantidade *'
							placeholder='Informe a Quantidade'
							cols='12 10 4 2'
							casas={2}
							readOnly={readOnly} />
					</Row>
					<Row>
						<Grid>
							<Table>
								<THead>
									<Tr>
										<Th>Matéria Prima</Th>
										<Th alignRight>Quantidade</Th>
									</Tr>
								</THead>

								{this.props.formularioValues.id ? (
									<TBody>
										{this.props.listaProducaoRacaoItem.map(item => {
											return (
												<Tr key={item.id}>
													<Td>{item.nome_materia_prima}</Td>
													<Td alignRight>{parseFloat(item.quantidade).toFixed(2).replace('.', ',')}</Td>
												</Tr>
											);
										})}
									</TBody>
								) : (
									<TBody>
										{this.props.listaMateriaPrima.map(item => {
											return (
												<Tr key={item.id}>
													<Td>{item.nome_materia_prima}</Td>
													<Td alignRight>{parseFloat(item.quantidade).toFixed(2).replace('.', ',')}</Td>
												</Tr>
											);
										})}
									</TBody>
								)}
							</Table>
						</Grid>
					</Row>

					<BoxButtonFooter>
						{this.props.excluir ? (
							<Button
								text='Excluir'
								submit type={'danger'}
								icon={'fa fa-trash'} />
						) : !this.props.formularioValues.id ? (
							<Button
								text='Salvar'
								submit
								type={'success'}
								icon={'fa fa-check'} />
						) : null}
						<Button
							text='Cancelar'
							type={'warning'}
							icon={'fa fa-chevron-left'}
							event={() => this.props.setModoTela('lista')} />
					</BoxButtonFooter>
				</Form>
			</ContentCard>
        )
    }


}

ProducaoRacaoForm = reduxForm({form: 'producaoRacaoForm', destroyOnUnmount: false})(ProducaoRacaoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	listaGranja: state.producaoRacao.listaGranja,
	listaRacao: state.producaoRacao.listaRacao,
	listaMateriaPrima: state.producaoRacao.listaMateriaPrima,
	listaProducaoRacaoItem: state.producaoRacao.listaProducaoRacaoItem,
	registro: state.producaoRacao.registro,
	formularioValues: getFormValues('producaoRacaoForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, getListaGranja, getListaRacao, getListaMateriaPrima, getListaProducaoRacaoItem }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ProducaoRacaoForm);
