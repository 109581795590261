const INITIAL_STATE = { registro: {}, modoTela: 'lista', lista: [], listaGranja: [], listaLocal: [], listaAnimal: [], listaItens: [] };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'PESO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};
        case 'PESO_LISTADO':
            return { ...state, lista: action.payload.data };
		case 'PESO_ITEM_LISTADO':
			return { ...state, listaItens: action.payload.data };
		case 'PESO_GRANJA_SELECT_LISTADO':
            return { ...state, listaGranja: action.payload.data };
		case 'PESO_LOCAL_SELECT_LISTADO':
            return { ...state, listaLocal: action.payload.data };
		case 'PESO_ANIMAL_SELECT_LISTADO':
            return { ...state, listaAnimal: action.payload.data };
		default:
            return state;
    }
}
