import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess, setError } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'LOTE_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('loteForm', registro)
    ];
}

export function setFiltro(filtro) {
    return {
        type: 'LOTE_SET_FILTRO',
        payload: filtro
    };
}

export function getLista(id_empresa) {
	return async (dispatch, getState) => {
		let listaLotes = [];
	    await axios.get(`${consts.API_URL}/lote?id_empresa=${id_empresa}`).then(async resp => {
			let listaAnimais = [];
			await axios.get(`${consts.API_URL}/animal?id_empresa=${id_empresa}`).then(resp => {
				listaAnimais = resp.data;
			});
			for (var i = 0; i < resp.data.length; i++) {
				let lote = resp.data[i];

				lote.listaAnimal = listaAnimais.filter(animal => animal.id_lote == lote.id);

				listaLotes.push(lote);
			}
		});

	    dispatch({
			type: 'LOTE_LISTADO',
			payload: listaLotes
		});
	};
}

export function getListaGranja(id_empresa) {
    const request = axios.get(`${consts.API_URL}/granja/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'LOTE_GRANJA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaAnimais(id_lote, id_empresa) {
    const request = axios.get(`${consts.API_URL}/animal?id_lote=${id_lote}&id_empresa=${id_empresa}`);
    return {
        type: 'LOTE_ANIMAL_LISTADO',
        payload: request
    };
}

export function getListaAnimaisFiltro(id_empresa, filtro) {
    const request = axios.get(`${consts.API_URL}/animal?status=${filtro.status}&id_granja=${filtro.id_granja}&id_empresa=${id_empresa}`);
    return {
        type: 'LOTE_ANIMAL_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/lote`, {
				...registro,
				id_empresa: getState().auth.usuario.id_empresa
			})
            .then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(getState().auth.usuario.id_empresa));
                dispatch(setModoTela('lista'));
            })
            .catch(e => {
                setErroAPI(e);
            });
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/lote?id=${registro.id}`)
            .then(resp => {
                setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(getState().auth.usuario.id_empresa));
                dispatch(setModoTela('lista'));
            })
            .catch(e => {
                setErroAPI(e);
            });
    };
}

export function transferenciaLote(idLote, listaAnimais, callback) {

    return async (dispatch, getState) => {

		if (!idLote) {
			setError('Lote não informado');
			return;
		}
		if (listaAnimais.length == 0) {
			setError('Nenhum animal selecionado');
			return;
		}

		for (var i = 0; i < listaAnimais.length; i++) {
			let animal = listaAnimais[i];
			if (animal.id_lote != idLote) {
		        await axios.post(`${consts.API_URL}/animal`, {
					...animal,
					id_lote: idLote
				}).then(resp => {
				}).catch(e => {
					setErroAPI(e);
				});
			}
		}
		setSuccess('Operação Realizada com sucesso.');
		dispatch(getLista(getState().auth.usuario.id_empresa));
		dispatch(getListaAnimais(null, getState().auth.usuario.id_empresa));
		callback();
    };
}
