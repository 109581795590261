const INITIAL_STATE = { registro: {}, modoTela: 'lista', lista: [], listaEstados: [], listaCidades: [] };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'GRANJA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};
        case 'GRANJA_LISTADO':
            return { ...state, modoTela: 'lista', lista: action.payload.data };
        case 'GRANJA_ESTADO_SELECT_LISTADO':
            return { ...state, listaEstados: action.payload.data };
        case 'GRANJA_CIDADE_SELECT_LISTADO':
            return { ...state, listaCidades: action.payload.data };
        default:
            return state;
    }
}
