import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';
import fs from 'fs';
import b64toBlob from 'b64-to-blob';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import Row from '../common/layout/row';
import Grid from '../common/layout/grid';
import Button from '../common/button/button';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndTextarea from '../common/form/labelAndTextarea';
import Select from '../common/form/select';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputDate from '../common/form/labelAndInputDate';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import NumberFormat from 'react-number-format';

import { initForm, setModoTela, getListaGranja, getListaCliente, getListaAnimal, getListaItens, adicionarItem, alterarItem, removerItem, ratearPesoVivo, ratearPesoCarcaca, ratearValorTotal } from './vendaActions';

class VendaForm extends Component {

	state = {
		filtroAnimal: '',
		listaItens: [],
		pesoVivoRateio: 0,
		pesoCarcacaRateio: 0,
		valorTotalRateio: 0
	};

    componentWillMount() {
		this.props.getListaGranja(this.props.sessao.id_empresa);
		this.props.getListaCliente(this.props.sessao.id_empresa);
		if (this.props.formularioValues.id) {
			this.props.getListaAnimal(this.props.formularioValues.id_granja, this.props.sessao.id_empresa);
			this.props.getListaItens(this.props.formularioValues.id, this.props.sessao.id_empresa);
		} else {
			this.props.getListaItens(0, this.props.sessao.id_empresa);
		}
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		let filtroAnimal = this.state.filtroAnimal ? this.state.filtroAnimal.toUpperCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&') : null;
		let listaAnimal = (this.props.listaAnimal || []).filter(animal => {

			if (!filtroAnimal) {
				return false;
			}

			let dadosAnimal = `${animal.numero_brinco}`;
			dadosAnimal = dadosAnimal.toUpperCase().replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

			return dadosAnimal.indexOf(filtroAnimal) != -1;

		});

		if (this.props.listaItens && (this.state.listaItens.length != this.props.listaItens.length
				|| this.state.listaItens.filter(itemVenda => {
					let retorno = false;
					this.props.listaItens.forEach(itemVendaProps => {
						if (itemVendaProps.id == itemVenda.id && itemVendaProps.datahora_alteracao != itemVenda.datahora_alteracao) {
							retorno = true;
						}
					});
					return retorno;
				}).length > 0)) {
			this.setState({
				...this.state,
				listaItens: this.props.listaItens.map(item => item)
			});
		}

		let femeaBase = parseInt(this.props.formularioValues.quantidade_nao_identificado_femea || 0);
		let machoBase = parseInt(this.props.formularioValues.quantidade_nao_identificado_macho || 0);

		let listaFaixaIdade = [
			{ nome: '0 - 12', faixaInicial: null, faixaFinal: 12, femea: 0, macho: 0 },
			{ nome: '13 - 24', faixaInicial: 13, faixaFinal: 24, femea: 0, macho: 0 },
			{ nome: '25 - 36', faixaInicial: 25, faixaFinal: 36, femea: 0, macho: 0 },
			{ nome: '36 +', faixaInicial: 37, faixaFinal: null, femea: femeaBase, macho: machoBase }
		];
		this.state.listaItens.forEach(item => {
			listaFaixaIdade = listaFaixaIdade.map(faixaIdade => {
				if ((faixaIdade.faixaInicial == null || item.idade >= faixaIdade.faixaInicial)
					&& (faixaIdade.faixaFinal == null || item.idade <= faixaIdade.faixaFinal)) {
					if (item.id_sexo == 1) {
						return {
							...faixaIdade,
							femea: faixaIdade.femea + 1
						}
					} else {
						return {
							...faixaIdade,
							macho: faixaIdade.macho + 1
						}
					}
				}
				return faixaIdade;
			});
		});

		let pesoVivo =  0;
		let pesoCarcaca = 0;
		let valorTotal = 0;

		let totalFemeas = 0;
		let totalMachos = 0;

        return (
			<div>
				<ContentCard>
					<Form event={this.props.handleSubmit}>
						<Row>
							<Field
								name='datahora_venda'
								component={LabelAndInputDateTime}
								label='Data e hora da Venda'
								placeholder='Informe a data e a hora'
								cols='8 4 3 2'
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='id_granja'
								component={Select}
								options={this.props.listaGranja}
								label='Granja *'
								cols='12 12 8 4'
								placeholder='Selecione a Granja'
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='id_cliente'
								component={Select}
								options={this.props.listaCliente}
								label='Cliente *'
								cols='12 12 8 4'
								placeholder='Selecione o Cliente'
								readOnly={readOnly} />
						</Row>
						{this.props.formularioValues.id ? (
							<Row>
								<Grid>
									<label>Animais sem identificação</label>
								</Grid>
							</Row>
						) : null}
						{this.props.formularioValues.id ? (
							<Row>
								<Field
									name='quantidade_nao_identificado_femea'
									component={LabelAndInputNumber}
									label='Fêmeas'
									cols='6 6 4 2'
									casas={0}
									placeholder='0'
									readOnly={readOnly} />
								<Field
									name='quantidade_nao_identificado_macho'
									component={LabelAndInputNumber}
									label='Machos'
									cols='6 6 4 2'
									casas={0}
									placeholder='0'
									readOnly={readOnly} />
							</Row>
						) : null}
						{this.props.formularioValues.id ? (
							<Row>
								<Field
									name='observacao_nao_identificado'
									component={LabelAndTextarea}
									label='Observação'
									placeholder='Observação dos animais sem identificação'
									cols='12 12 8 6'
									readOnly={readOnly} />
							</Row>
						) : null}
						<Row>
							<Grid>
								<label>Anexos</label>
								<Tr>
									{this.props.formularioValues.anexos.map(item => (
										<Td key={item.id} alignCenter style={{ width: 150, padding: 4 }}>
											<p>{item.nome}</p>
											<span class="input-group-btn">
												<button type='button' class='btn btn-primary btn-flat' style={{ marginRight: 4 }}
													onClick={() => {
														let blob = b64toBlob(item.anexo.split(',')[1], item.anexo.split(',')[0]);
														window.location = URL.createObjectURL(blob);
													}}>
													<i class='fa fa-download' aria-hidden='true'></i>
												</button>
												<button type='button' class='btn btn-danger btn-flat'
													onClick={() => {
														let lista = this.props.formularioValues.anexos.filter(anexo => anexo.id != item.id);
														this.props.initForm({
															...this.props.formularioValues,
															anexos: lista
														}, false);
														this.setState({...this.state});
													}}>
													<i class='fa fa-trash' aria-hidden='true'></i>
												</button>
											</span>
										</Td>
									))}
									<Td alignCenter>
										<input
											name='anexos'
											type='file'
											accept=''
											multiple='multiple'
											onChange={event => {
												event.preventDefault();
												for (var i = 0; i < event.target.files.length; i++) {
													let file = event.target.files[i];
													let reader = new FileReader();
													reader.onloadend = (data) => {

														let lista = this.props.formularioValues.anexos;
														lista.push({
															id: new Date().getTime(),
															nome: data.target.fileName,
															anexo: reader.result
														});
														this.props.initForm({
															...this.props.formularioValues,
															anexos: lista
														}, false);
														this.setState({...this.state});
													}

													reader.fileName = file.name;
													reader.readAsDataURL(file);
												}
												event.target.value = null;
											}} />
									</Td>
								</Tr>
							</Grid>
						</Row>
						<br />
						<BoxButtonFooter>
							{this.props.excluir ? (
								<Button
									text='Excluir'
									submit type={'danger'}
									icon={'fa fa-trash'} />
							) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
							)}
							<Button
								text='Voltar'
								type={'warning'}
								icon={'fa fa-chevron-left'}
								event={() => this.props.setModoTela('lista')} />
						</BoxButtonFooter>
					</Form>
				</ContentCard>

				{this.props.formularioValues.id ? (
					<ContentCard>
						<ContentCardHeader title='Itens' />
						<Row>
							<Grid cols='12 8 6'>
								<label>Animal</label>
								<div style={{ display: 'flex', flex: 2 }}>
									<div style={{ flex: 1 }}>
										<input className='form-control'
											placeholder={'Selecione o Animal'}
											value={this.state.filtroAnimal}
											onChange={(data => {
												this.setState({
													...this.state,
													filtroAnimal: data.target.value
												});
											})} />
									</div>
								</div>
								{listaAnimal.length > 0 ? (
									<div style={{ display: 'flex', overflowX: 'auto', overflowY: 'auto', maxHeight: 150 }}>
										<table className='table table-bordered table-striped dataTable'>
											<THead>
												<Tr>
													<Th>Animal</Th>
													<Th>Lote</Th>
													<Th>Sexo</Th>
													<Th>Idade</Th>
												</Tr>
											</THead>
											<TBody>
												{listaAnimal.map(animal => {
													return (
														<Tr key={animal.id}
															onClick={() => {
																this.props.adicionarItem({
																	id_venda: this.props.formularioValues.id,
																	id_animal: animal.id,
																	peso_vivo: 0,
																	peso_carcaca: 0,
																	valor_vivo: 0,
																	valor_carcaca: 0,
																	id_granja: this.props.formularioValues.id_granja
																});
																this.setState({
																	...this.state,
																	filtroAnimal: ''
																});
															}}>
															<Td>{animal.numero_brinco}</Td>
															<Td>{animal.numero_lote}</Td>
															<Td>{animal.nome_sexo}</Td>
															<Td>{parseInt(animal.idade_atual)}</Td>
														</Tr>
													);
												})}
											</TBody>
										</table>
									</div>
								) : null}

								<br />
							</Grid>
							<Grid cols='12'>
								<Table>
									<THead>
										<Tr>
											<Th>Lote</Th>
											<Th>Animal</Th>
											<Th>Sexo</Th>
											<Th>Idade</Th>
											<Th alignCenter>Peso</Th>
											<Th alignRight>Peso Vivo</Th>
											<Th alignRight>Peso Carcaça</Th>
											<Th alignRight>Valor Vivo</Th>
											<Th alignRight>Valor Carcaça</Th>
											<Th alignRight>Total</Th>
											<Th alignCenter acoes>Ações</Th>
										</Tr>
									</THead>
									<TBody>
										{this.state.listaItens.map(item => {

											pesoVivo += parseFloat(item.peso_vivo);
											pesoCarcaca += parseFloat(item.peso_carcaca);
											valorTotal += parseFloat(item.valor_total);

											return (
												<Tr key={item.id}>
													<Td>{item.numero_lote}</Td>
													<Td>{item.numero_brinco_animal}</Td>
													<Td>{item.nome_sexo}</Td>
													<Td>{item.idade}</Td>
													<Td alignCenter>{parseInt(item.peso_entrada)} - {parseInt(item.peso_atual) || parseInt(item.peso_entrada)}</Td>
													<Td alignRight>
														{item.alteracao ? (
															<NumberFormat
																style={{ textAlign: 'right' }}
																className='form-control'
																decimalSeparator={","} decimalScale={4} fixedDecimalScale={4}
																value={String(item.peso_vivo || '0').replace('.', ',')}
																onChange={data => {
																	let lista = this.state.listaItens.map(itemVenda => {
																		if (itemVenda.id == item.id) {
																			return {
																				...itemVenda,
																				peso_vivo: data.target.value.replace(',', '.')
																			};
																		} else {
																			return itemVenda;
																		}
																	});
																	this.setState({
																		...this.state,
																		listaItens: lista
																	});
																}} />
														) : (item.peso_vivo || '0,0000').replace('.', ',')}
													</Td>
													<Td alignRight>
														{item.alteracao ? (
															<NumberFormat
																style={{ textAlign: 'right' }}
																className='form-control'
																decimalSeparator={","} decimalScale={4} fixedDecimalScale={4}
																value={String(item.peso_carcaca || '0').replace('.', ',')}
																onChange={data => {
																	let lista = this.state.listaItens.map(itemVenda => {
																		if (itemVenda.id == item.id) {
																			return {
																				...itemVenda,
																				peso_carcaca: data.target.value.replace(',', '.')
																			};
																		} else {
																			return itemVenda;
																		}
																	});
																	this.setState({
																		...this.state,
																		listaItens: lista
																	});
																}} />
														) : (item.peso_carcaca || '0,0000').replace('.', ',')}
													</Td>
													<Td alignRight>
														{item.alteracao ? (
															<NumberFormat
																style={{ textAlign: 'right' }}
																className='form-control'
																decimalSeparator={","} decimalScale={4} fixedDecimalScale={4}
																value={String(item.valor_vivo || '0').replace('.', ',')}
																onChange={data => {
																	let lista = this.state.listaItens.map(itemVenda => {
																		if (itemVenda.id == item.id) {
																			return {
																				...itemVenda,
																				valor_vivo: data.target.value.replace(',', '.')
																			};
																		} else {
																			return itemVenda;
																		}
																	});
																	this.setState({
																		...this.state,
																		listaItens: lista
																	});
																}} />
														) : (item.valor_vivo || '0,0000').replace('.', ',')}
													</Td>
													<Td alignRight>
														{item.alteracao ? (
															<NumberFormat
																style={{ textAlign: 'right' }}
																className='form-control'
																decimalSeparator={","} decimalScale={4} fixedDecimalScale={4}
																value={String(item.valor_carcaca || '0').replace('.', ',')}
																onChange={data => {
																	let lista = this.state.listaItens.map(itemVenda => {
																		if (itemVenda.id == item.id) {
																			return {
																				...itemVenda,
																				valor_carcaca: data.target.value.replace(',', '.')
																			};
																		} else {
																			return itemVenda;
																		}
																	});
																	this.setState({
																		...this.state,
																		listaItens: lista
																	});
																}} />
														) : (item.valor_carcaca || '0,0000').replace('.', ',')}
													</Td>
													<Td alignRight>{(item.valor_total || '0,00').replace('.', ',')}</Td>
													<Td alignCenter>
														{item.alteracao ? (
															<div>
																<ButtonTable
																	type={'success'}
																	icon={'fa fa-check'}
																	event={() => {
																		this.props.alterarItem({
																			...item,
																			id_granja: this.props.formularioValues.id_granja
																		});
																		this.setState({
																			...this.state,
																			alteracao: false
																		});
																	}} />

																<ButtonTable
																	type={'danger'}
																	icon={'fa fa-times'}
																	event={() => {
																		this.setState({
																			...this.state,
																			listaItens: this.props.listaItens.map(item => item),
																			alteracao: false
																		});
																	}} />
															</div>
														) : (
															<div>
																<ButtonTable
																	type={this.state.alteracao ? 'disabled' : 'warning'}
																	icon={'fa fa-pencil'}
																	disabled={true}
																	event={() => {
																		if (!this.state.alteracao) {
																			let lista = this.state.listaItens.map(itemVenda => {
																				return {
																					...itemVenda,
																					alteracao: itemVenda.id == item.id
																				};
																			});
																			this.setState({
																				...this.state,
																				listaItens: lista,
																				alteracao: true
																			});
																		}
																	}} />

																<ButtonTable
																	type={this.state.alteracao ? 'disabled' : 'danger'}
																	icon={'fa fa-trash-o'}
																	event={() => {
																		if (!this.state.alteracao) {
																			this.props.removerItem({
																				...item,
																				id_granja: this.props.formularioValues.id_granja
																			});
																		}
																	}} />
															</div>
														)}
													</Td>
												</Tr>
											);
										})}
										<Tr>
											<Th>Total</Th>
											<Th></Th>
											<Th></Th>
											<Th></Th>
											<Th></Th>
											<Th alignRight>{String(pesoVivo.toFixed(4)).replace('.', ',')}</Th>
											<Th alignRight>{String(pesoCarcaca.toFixed(4)).replace('.', ',')}</Th>
											<Th alignRight></Th>
											<Th alignRight></Th>
											<Th alignRight>{String(valorTotal.toFixed(4)).replace('.', ',')}</Th>
											<Th alignCenter></Th>
										</Tr>
									</TBody>
								</Table>
							</Grid>
							<Grid cols='12'>
								<Tr style={{ height: 40, verticalAlign: 'middle' }}>
									<Td>
										<NumberFormat
											style={{ textAlign: 'left', width: 120 }}
											className='form-control'
											decimalSeparator={","} decimalScale={4} fixedDecimalScale={4}
											value={String(this.state.pesoVivoRateio).replace('.', ',')}
											onChange={data => {
												this.setState({
													...this.state,
													pesoVivoRateio: data.target.value.replace(',', '.')
												});
											}} />
									</Td>
									<Td>
										<Button
											text='Ratear peso vivo'
											type={'success'}
											icon={'fa fa-random'}
											event={() => {
												this.props.ratearPesoVivo(this.props.sessao.id_empresa, this.props.formularioValues.id, this.props.formularioValues.id_granja, this.state.pesoVivoRateio);
											}} />
									</Td>
								</Tr>
								<Tr style={{ height: 40, verticalAlign: 'middle' }}>
									<Td>
										<NumberFormat
											style={{ textAlign: 'left', width: 120 }}
											className='form-control'
											decimalSeparator={","} decimalScale={4} fixedDecimalScale={4}
											value={String(this.state.pesoCarcacaRateio).replace('.', ',')}
											onChange={data => {
												this.setState({
													...this.state,
													pesoCarcacaRateio: data.target.value.replace(',', '.')
												});
											}} />
									</Td>
									<Td>
										<Button
											text='Ratear peso carcaça'
											type={'success'}
											icon={'fa fa-random'}
											event={() => {
												this.props.ratearPesoCarcaca(this.props.sessao.id_empresa, this.props.formularioValues.id, this.props.formularioValues.id_granja, this.state.pesoCarcacaRateio);
											}} />
									</Td>
								</Tr>
								<Tr style={{ height: 40, verticalAlign: 'middle' }}>
									<Td>
										<NumberFormat
											style={{ textAlign: 'left', width: 120 }}
											className='form-control'
											decimalSeparator={","} decimalScale={2} fixedDecimalScale={2}
											value={String(this.state.valorTotalRateio).replace('.', ',')}
											onChange={data => {
												this.setState({
													...this.state,
													valorTotalRateio: data.target.value.replace(',', '.')
												});
											}} />
									</Td>
									<Td>
										<Button
											text='Ratear Valor total'
											type={'success'}
											icon={'fa fa-random'}
											event={() => {
												this.props.ratearValorTotal(this.props.sessao.id_empresa, this.props.formularioValues.id, this.props.formularioValues.id_granja, this.state.valorTotalRateio);
											}} />
									</Td>
								</Tr>
								<br />
							</Grid>
							<Grid cols='12 12 6'>
								<Table>
									<THead>
										<Tr>
											<Th alignCenter>Idade</Th>
											<Th alignCenter>Fêmea</Th>
											<Th alignCenter>Macho</Th>
										</Tr>
									</THead>
									<TBody>
										{listaFaixaIdade.map(faixaIdade => {

											totalFemeas += faixaIdade.femea;
											totalMachos += faixaIdade.macho;

											return (
												<Tr key={faixaIdade.nome}>
													<Td alignCenter>{faixaIdade.nome}</Td>
													<Td alignCenter>{faixaIdade.femea}</Td>
													<Td alignCenter>{faixaIdade.macho}</Td>
												</Tr>
											);
										})}
										<Tr>
											<Th alignCenter>Total</Th>
											<Th alignCenter>{totalFemeas}</Th>
											<Th alignCenter>{totalMachos}</Th>
										</Tr>
									</TBody>
								</Table>
							</Grid>
						</Row>
					</ContentCard>
				) : null}
			</div>
        )
    }


}

VendaForm = reduxForm({form: 'vendaForm', destroyOnUnmount: false})(VendaForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	listaGranja: state.venda.listaGranja,
	listaCliente: state.venda.listaCliente,
	listaAnimal: state.venda.listaAnimal,
	listaItens: state.venda.listaItens,
	registro: state.venda.registro,
	formularioValues: getFormValues('vendaForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({ initForm, setModoTela, getListaGranja, getListaCliente, getListaAnimal, getListaItens, adicionarItem, alterarItem, removerItem, ratearPesoVivo, ratearPesoCarcaca, ratearValorTotal }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(VendaForm);
