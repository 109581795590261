import axios from 'axios';
import { showTabs, selectTab } from '../../common/tab/tabActions';
import consts from '../../consts';
import { setErroAPI, setSuccess } from '../../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import * as moment from 'moment';
import 'moment/locale/pt-br';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'ANIMAL_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function setModoTelaLote(modo, registro = {}) {
	return {
        type: 'LOTE_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('animalForm', {
			...registro,
			data_pesagem: registro.data_pesagem ? moment(registro.data_pesagem).format('DD/MM/YYYY') : null
		})
    ];
}

export function getLista(id_lote, id_empresa) {
    const request = axios.get(`${consts.API_URL}/animal?id_lote=${id_lote}&id_empresa=${id_empresa}`);
    return {
        type: 'ANIMAL_LISTADO',
        payload: request
    };
}

export function getListaUltimoIncluidoLote(id_empresa) {
    const request = axios.get(`${consts.API_URL}/animal/listarUltimoIncluidoLote?id_empresa=${id_empresa}`);
    return {
        type: 'ANIMAL_ULTIMO_INCLUIDO_LOTE_LISTADO',
        payload: request
    };
}

export function getListaGranja(id_empresa) {
    const request = axios.get(`${consts.API_URL}/granja/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'ANIMAL_GRANJA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaFornecedor(id_empresa) {
    const request = axios.get(`${consts.API_URL}/pessoa/listarselect?id_empresa=${id_empresa}&fornecedor=true`);
    return {
        type: 'ANIMAL_FORNECEDOR_SELECT_LISTADO',
        payload: request
    };
}

export function getListaLocal(id_empresa) {
    const request = axios.get(`${consts.API_URL}/local/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'ANIMAL_LOCAL_SELECT_LISTADO',
        payload: request
    };
}

export function getListaFamilia(id_empresa) {
    const request = axios.get(`${consts.API_URL}/familia/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'ANIMAL_FAMILIA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaSexo() {
    const request = axios.get(`${consts.API_URL}/sexo/listarselect`);
    return {
        type: 'ANIMAL_SEXO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaRaca(id_empresa) {
    const request = axios.get(`${consts.API_URL}/raca/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'ANIMAL_RACA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaBrinco(id_empresa) {
    const request = axios.get(`${consts.API_URL}/brinco/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'ANIMAL_BRINCO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaPelagem(id_empresa) {
    const request = axios.get(`${consts.API_URL}/pelagem/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'ANIMAL_PELAGEM_SELECT_LISTADO',
        payload: request
    };
}

export function getListaMae(id_empresa) {
    const request = axios.get(`${consts.API_URL}/animal?status=1&id_empresa=${id_empresa}&id_sexo=1`);
    return {
        type: 'ANIMAL_MAE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaLote(id_empresa) {
    const request = axios.get(`${consts.API_URL}/lote/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'ANIMAL_LOTE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaAnimaisFiltro(id_empresa, filtro) {
    const request = axios.get(`${consts.API_URL}/animal?status=${filtro.status}&id_granja=${filtro.id_granja}&id_empresa=${id_empresa}`);
    return {
        type: 'LOTE_ANIMAL_LISTADO',
        payload: request
    };
}

export function salvar(registro) {

    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/animal`, {
			...registro,
			data_pesagem: registro.data_pesagem ? `${registro.data_pesagem.split('/')[2]}/${registro.data_pesagem.split('/')[1]}/${registro.data_pesagem.split('/')[0]}` : null,
			id_empresa: getState().auth.usuario.id_empresa
		})
            .then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(setModoTelaLote('lista'));
				dispatch(getListaAnimaisFiltro(getState().auth.usuario.id_empresa, getState().lote.filtro));
            })
            .catch(e => {
                setErroAPI(e);
            });
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/animal?id=${registro.id}`)
            .then(resp => {
                setSuccess('Operação Realizada com sucesso.');
				dispatch(setModoTelaLote('lista'));
				dispatch(getListaAnimaisFiltro(getState().auth.usuario.id_empresa, getState().lote.filtro));
            })
            .catch(e => {
                setErroAPI(e);
            });
    };
}
