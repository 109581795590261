import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'VACINA_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export async function initForm(registro = {}) {
	if (registro && registro.id && !registro.listaRacaoFormula) {
		await axios.get(`${consts.API_URL}/racaoformula?id_racao=${registro.id}&id_empresa=${registro.id_empresa}`)
		.then(result => {
			registro = {
				...registro,
				listaRacaoFormula: result.data
			}
		}).catch(ex => {
			registro = {
				...registro,
				listaRacaoFormula: []
			}
		})
	} else if (!registro.listaRacaoFormula) {
		registro = {
			...registro,
			listaRacaoFormula: []
		}
	}

    return [
        initialize('produtoForm', registro)
    ];
}

export function getLista(id_empresa) {
    const request = axios.get(`${consts.API_URL}/produto?id_empresa=${id_empresa}`);
    return {
        type: 'VACINA_LISTADO',
        payload: request
    };
}

export function getListaProdutoTipo(id_empresa) {
    const request = axios.get(`${consts.API_URL}/produtotipo/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'VACINA_PRODUTO_TIPO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaUnidadeMedida() {
    const request = axios.get(`${consts.API_URL}/unidademedida/listarselect`);
    return {
        type: 'VACINA_UNIDADE_MEDIDA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaVacinaVia() {
    const request = axios.get(`${consts.API_URL}/vacinavia/listarselect`);
    return {
        type: 'VACINA_VIA_SELECT_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/produto`, {
				...registro,
				id_empresa: getState().auth.usuario.id_empresa
			})
            .then(resp => {

				//Adiciona a fórmula da ração
				registro.listaRacaoFormula.forEach(async racaoFormula => {

					if (racaoFormula.ativo) {
						await axios.post(`${consts.API_URL}/racaoformula`, {
							...racaoFormula,
							id_racao: registro.id || resp.data,
							id_empresa: getState().auth.usuario.id_empresa
						});
					} else if (racaoFormula.id) {
						await axios.delete(`${consts.API_URL}/racaoformula?id=${racaoFormula.id}`);
					}

				});


				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(getState().auth.usuario.id_empresa));
                dispatch(setModoTela('lista'));
            })
            .catch(e => {
                setErroAPI(e);
            });
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/produto?id=${registro.id}`)
            .then(resp => {
                setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(getState().auth.usuario.id_empresa));
                dispatch(setModoTela('lista'));
            })
            .catch(e => {
                setErroAPI(e);
            });
    };
}
