const INITIAL_STATE = { registro: {}, modoTela: 'lista', lista: [], listaFamilia: [], listaSexo: [], listaRaca: [] };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'PREVISAO_PESO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};
        case 'PREVISAO_PESO_LISTADO':
            return { ...state, modoTela: 'lista', lista: action.payload.data };
		case 'PREVISAO_PESO_FAMILIA_SELECT_LISTADO':
            return { ...state, listaFamilia: action.payload.data };
		case 'PREVISAO_PESO_SEXO_SELECT_LISTADO':
            return { ...state, listaSexo: action.payload.data };
		case 'PREVISAO_PESO_RACA_SELECT_LISTADO':
            return { ...state, listaRaca: action.payload.data };
        default:
            return state;
    }
}
