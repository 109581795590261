import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import Button from '../common/button/button';

import { setModoTela, getLista } from './estoqueActions';
import { getDataAtual, getDataFormatada, getData, getDataHoraBanco, getDataHoraFormatada } from '../utils/dataUtils';

class Estoque extends Component {

    componentWillMount() {
        this.props.getLista(this.props.sessao.id_empresa);
    }

    render() {
        return (
            <div>
                <ContentHeader title='Estoque' small='Cadastro' />
                <Content>
					{this.lista()}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<Table>
					<THead>
						<Tr>
							<Th>Data e Hora</Th>
							<Th>Tipo</Th>
							<Th>Granja</Th>
							<Th>Produto</Th>
							<Th alignRight>Quantidade</Th>
							<Th alignCenter>E/S</Th>
							<Th alignRight>Saldo</Th>
						</Tr>
					</THead>
					<TBody>
						{this.props.lista.map(item => (
							<Tr key={item.id}>
								<Td>{getDataHoraFormatada(item.datahora_movimento, 'DD/MM/YYYY HH:mm:ss')}</Td>
								<Td>{item.nome_tipo}</Td>
								<Td>{item.nome_granja}</Td>
								<Td>{item.nome_produto}</Td>
								<Td alignRight>{(item.quantidade_entrada > 0 ? item.quantidade_entrada : item.quantidade_saida).replace('.', ',')}</Td>
								<Td alignCenter>{item.quantidade_entrada > 0 ? 'E' : 'S'}</Td>
								<Td alignRight>{item.quantidade_final.replace('.', ',')}</Td>
							</Tr>
						))}
					</TBody>
				</Table>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.estoque.modoTela,
	lista: state.estoque.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, getLista }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Estoque);
