import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess, setError } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import { getDataAtual, getDataFormatada, getData, getDataBanco, getDataHoraBanco, getDataHoraFormatada } from '../utils/dataUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'COMPRA_ANIMAL_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = { datahora_compra: getDataAtual() }) {
    return [
        initialize('compraAnimalForm', {
			...registro,
			datahora_compra: getDataHoraFormatada(registro.datahora_compra, 'DD/MM/YYYY HH:mm')
		})
    ];
}

export function getLista(id_empresa) {
    const request = axios.get(`${consts.API_URL}/compraAnimal?id_empresa=${id_empresa}`);
    return {
        type: 'COMPRA_ANIMAL_LISTADO',
        payload: request
    };
}

export function getListaGranja(id_empresa) {
    const request = axios.get(`${consts.API_URL}/granja/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'COMPRA_ANIMAL_GRANJA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaFornecedor(id_empresa) {
    const request = axios.get(`${consts.API_URL}/pessoa/listarselect?id_empresa=${id_empresa}&fornecedor=true`);
    return {
        type: 'COMPRA_ANIMAL_CLIENTE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaAnimal(id_granja, id_empresa) {
    const request = axios.get(`${consts.API_URL}/animal/listarAbertos?id_granja=${id_granja}&id_empresa=${id_empresa}`);
    return {
        type: 'COMPRA_ANIMAL_ANIMAL_SELECT_LISTADO',
        payload: request
    };
}

export function getListaItens(id_compra_animal, id_empresa) {
    const request = axios.get(`${consts.API_URL}/compraAnimalItem?id_compra_animal=${id_compra_animal}&id_empresa=${id_empresa}`);
    return {
        type: 'COMPRA_ANIMAL_ITEM_LISTADO',
        payload: request
    };
}

export function salvar(registro) {

    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/compraAnimal`, {
			...registro,
			datahora_compra: getDataHoraBanco(registro.datahora_compra),
			id_empresa: getState().auth.usuario.id_empresa
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(getState().auth.usuario.id_empresa));
            dispatch(setModoTela('cadastro'));
			if (!registro.id) {
				registro.id = resp.data;
			}
			registro.datahora_compra = getDataHoraBanco(registro.datahora_compra);
			dispatch(initForm(registro));
			dispatch(getListaAnimal(registro.id_granja, getState().auth.usuario.id_empresa));
        }).catch(e => {
            setErroAPI(e);
        });
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/compraAnimal?id=${registro.id}`)
            .then(resp => {
                setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(getState().auth.usuario.id_empresa));
                dispatch(setModoTela('lista'));
            })
            .catch(e => {
                setErroAPI(e);
            });
    };
}

export function adicionarItem(registro) {

    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/compraAnimalItem`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaItens(registro.id_compra_animal, getState().auth.usuario.id_empresa));
			dispatch(getListaAnimal(registro.id_granja, getState().auth.usuario.id_empresa));
			dispatch(getLista());
        }).catch(e => {
            setErroAPI(e);
        });
    };
}

export function alterarItem(registro) {

    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/compraAnimalItem`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaItens(registro.id_compra_animal, getState().auth.usuario.id_empresa));
			dispatch(getListaAnimal(registro.id_granja, getState().auth.usuario.id_empresa));
			dispatch(getLista(getState().auth.usuario.id_empresa));
        }).catch(e => {
            setErroAPI(e);
        });
    };
}

export function removerItem(registro) {

    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/compraAnimalItem?id=${registro.id}`, {
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaItens(registro.id_compra_animal, getState().auth.usuario.id_empresa));
			dispatch(getListaAnimal(registro.id_granja, getState().auth.usuario.id_empresa));
			dispatch(getLista(), getState().auth.usuario.id_empresa);
        }).catch(e => {
            setErroAPI(e);
        });
    };
}

export function ratearPeso(id_empresa, id_compra_animal, id_granja, peso) {

	return async (dispatch, getState) => {
		let listaItens = [];
		await axios.get(`${consts.API_URL}/compraAnimalItem?id_empresa=${id_empresa}&id_compra_animal=${id_compra_animal}`)
		.then(result => {
			listaItens = result.data;
		}).catch(e => {
			console.log(e);
		});
		let rateio = parseFloat((peso / listaItens.length).toFixed(4));
		let rateado = 0;
		listaItens = listaItens.map(item => {
			item.peso = rateio;
			rateado += rateio;
			return item;
		});
		let resto  = parseFloat((peso - rateado).toFixed(4));
		console.log(rateado);
		console.log(resto);
		while (resto != 0) {
			listaItens = listaItens.map(item => {
				if (resto >= 0.0001) {
					item.peso += 0.0001;
					resto -= 0.0001;
				} else if (resto <= -0.0001) {
					item.peso -= 0.0001;
					resto += 0.0001;
				} else {
					resto = 0;
				}
				return item;
			});
		}
		console.log(resto);
		for (var i = 0; i < listaItens.length; i++) {
			let item = listaItens[i];
			await axios.post(`${consts.API_URL}/compraAnimalItem`, {
				...item
			}).then(result => {
	        }).catch(e => {

	        });
		}

		setSuccess('Operação Realizada com sucesso.');
		dispatch(getListaItens(id_compra_animal, getState().auth.usuario.id_empresa));
		dispatch(getListaAnimal(id_granja, getState().auth.usuario.id_empresa));
		dispatch(getLista(getState().auth.usuario.id_empresa));

	}

}

export function ratearValor(id_empresa, id_compra_animal, id_granja, valor) {

	return async (dispatch, getState) => {
		let listaItens = [];
		await axios.get(`${consts.API_URL}/compraAnimalItem?id_empresa=${id_empresa}&id_compra_animal=${id_compra_animal}`)
		.then(result => {
			listaItens = result.data;
		}).catch(e => {
			console.log(e);
		});
		let rateado = 0;
		let pesoInformado = true;
		let pesoTotal = 0
		for (var i = 0; i < listaItens.length; i++) {
			pesoTotal += parseFloat(listaItens[i].peso || 0);
		}

		listaItens = listaItens.map(item => {
			if (!item.peso || item.peso <= 0) {
				pesoInformado = false;
			}
			let rateio = item.peso ? parseFloat(((valor * item.peso) / pesoTotal).toFixed(2)) : 0.00;

			item.valor = rateio;
			rateado += rateio;
			return item;
		});

		if (!pesoInformado) {
			setError("Informe todos os pesos");
			return;
		}

		let resto  = parseFloat((valor - rateado).toFixed(2));
		while (resto != 0) {
			listaItens = listaItens.map(item => {
				if (resto >= 0.01) {
					item.valor += 0.01;
					resto -= 0.01;
				} else if (resto <= -0.01) {
					item.valor -= 0.01;
					resto += 0.01;
				} else {
					resto = 0;
				}
				return item;
			});
		}

		for (var i = 0; i < listaItens.length; i++) {
			let item = listaItens[i];
			await axios.post(`${consts.API_URL}/compraAnimalItem`, {
				...item
			}).then(result => {
			}).catch(e => {

			});
		}

		setSuccess('Operação Realizada com sucesso.');
		dispatch(getListaItens(id_compra_animal, getState().auth.usuario.id_empresa));
		dispatch(getListaAnimal(id_granja, getState().auth.usuario.id_empresa));
		dispatch(getLista(getState().auth.usuario.id_empresa));
	}

}
