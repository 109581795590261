import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { getDataAtual, getDataFormatada, getData, getDataHoraBanco, getDataHoraFormatada } from '../utils/dataUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'ESTOQUE_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}

export function getLista(id_empresa) {
    const request = axios.get(`${consts.API_URL}/estoque?id_empresa=${id_empresa}`);
    return {
        type: 'ESTOQUE_LISTADO',
        payload: request
    };
}
