import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector, getFormValues } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Button from '../common/button/button';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';
import LabelAndInputDateTime from '../common/form/labelAndInputDateTime';

import { setModoTela, getListaGranja, getListaLote, getListaAnimal, getListaProduto } from './alimentacaoActions';

class AlimentacaoForm extends Component {

    componentWillMount() {
		this.props.getListaGranja(this.props.sessao.id_empresa);
		this.props.getListaLote(this.props.sessao.id_empresa);
		this.props.getListaAnimal(this.props.sessao.id_empresa);
		this.props.getListaProduto(this.props.sessao.id_empresa);
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<Row>
						<Field
							name='id_granja'
							component={Select}
							options={this.props.listaGranja}
							label='Granja *'
							cols='12 12 8 4'
							placeholder='Selecione a Granja'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_lote'
							component={Select}
							options={this.props.listaLote}
							label='Lote'
							cols='12 12 8 4'
							placeholder='Selecione o Lote'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_animal'
							component={Select}
							options={this.props.listaAnimal.filter(item => item.id_lote == this.props.formularioValues.id_lote)}
							label='Animal'
							cols='12 12 8 4'
							placeholder={this.props.formularioValues.id_lote > 0 ? 'Todos' : 'Selecione o Lote'}
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_produto'
							component={Select}
							options={this.props.listaProduto}
							label='Produto *'
							cols='12 12 8 4'
							placeholder='Selecione o Produto'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='datahora_alimentacao'
							component={LabelAndInputDateTime}
							label='Data da Alimentacao'
							placeholder='Informe a data'
							cols='8 4 3 2'
							readOnly={readOnly} />
	  				</Row>
					<Row>
						<Field
							name='quantidade'
							component={LabelAndInputNumber}
							label='Quantidade *'
							placeholder='Informe a Quantidade'
							cols='12 10 4 2'
							casas={2}
							readOnly={readOnly} />
					</Row>

					<BoxButtonFooter>
						{this.props.excluir ? (
							<Button
								text='Excluir'
								submit type={'danger'}
								icon={'fa fa-trash'} />
						) : (
							<Button
								text='Salvar'
								submit
								type={'success'}
								icon={'fa fa-check'} />
						)}
						<Button
							text='Cancelar'
							type={'warning'}
							icon={'fa fa-chevron-left'}
							event={() => this.props.setModoTela('lista')} />
					</BoxButtonFooter>
				</Form>
			</ContentCard>
        )
    }


}

AlimentacaoForm = reduxForm({form: 'alimentacaoForm', destroyOnUnmount: false})(AlimentacaoForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	listaGranja: state.alimentacao.listaGranja,
	listaLote: state.alimentacao.listaLote,
	listaAnimal: state.alimentacao.listaAnimal,
	listaProduto: state.alimentacao.listaProduto,
	registro: state.alimentacao.registro,
	formularioValues: getFormValues('alimentacaoForm')(state)
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, getListaGranja, getListaLote, getListaAnimal, getListaProduto }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(AlimentacaoForm);
