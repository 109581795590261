import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess, setError } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import { getDataAtual, getDataFormatada, getData, getDataBanco, getDataHoraBanco, getDataHoraFormatada } from '../utils/dataUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'PESO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = { datahora_pesagem: getDataAtual() }) {
    return [
        initialize('pesoForm', {
			...registro,
			datahora_pesagem: getDataHoraFormatada(registro.datahora_pesagem, 'DD/MM/YYYY HH:mm')
		})
    ];
}

export function getLista(id_empresa) {
    const request = axios.get(`${consts.API_URL}/peso?id_empresa=${id_empresa}`);
    return {
        type: 'PESO_LISTADO',
        payload: request
    };
}

export function getListaGranja(id_empresa) {
    const request = axios.get(`${consts.API_URL}/granja/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'PESO_GRANJA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaLocal(id_empresa) {
    const request = axios.get(`${consts.API_URL}/local/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'PESO_LOCAL_SELECT_LISTADO',
        payload: request
    };
}

export function getListaAnimal(id_empresa) {
    const request = axios.get(`${consts.API_URL}/animal/listarAbertos?id_empresa=${id_empresa}`);
    return {
        type: 'PESO_ANIMAL_SELECT_LISTADO',
        payload: request
    };
}

export function getListaItens(id_peso, id_empresa) {
    const request = axios.get(`${consts.API_URL}/pesoItem?id_peso=${id_peso}&id_empresa=${id_empresa}`);
    return {
        type: 'PESO_ITEM_LISTADO',
        payload: request
    };
}

export function salvar(registro) {

    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/peso`, {
			...registro,
			datahora_pesagem: getDataHoraBanco(registro.datahora_pesagem),
			id_empresa: getState().auth.usuario.id_empresa
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(getState().auth.usuario.id_empresa));
            dispatch(setModoTela('cadastro'));
			if (!registro.id) {
				registro.id = resp.data;
			}
			registro.datahora_pesagem = getDataHoraBanco(registro.datahora_pesagem);
			dispatch(initForm(registro));
			dispatch(getListaAnimal(getState().auth.usuario.id_empresa));
        }).catch(e => {
            setErroAPI(e);
        });
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/peso?id=${registro.id}`)
            .then(resp => {
                setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(getState().auth.usuario.id_empresa));
                dispatch(setModoTela('lista'));
            })
            .catch(e => {
                setErroAPI(e);
            });
    };
}

export function adicionarItem(registro) {

    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/pesoItem`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaItens(registro.id_peso, getState().auth.usuario.id_empresa));
			dispatch(getListaAnimal(getState().auth.usuario.id_empresa));
			dispatch(getLista(getState().auth.usuario.id_empresa));
        }).catch(e => {
            setErroAPI(e);
        });
    };
}

export function alterarItem(registro) {

    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/pesoItem`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaItens(registro.id_peso, getState().auth.usuario.id_empresa));
			dispatch(getListaAnimal(getState().auth.usuario.id_empresa));
			dispatch(getLista(getState().auth.usuario.id_empresa));
        }).catch(e => {
            setErroAPI(e);
        });
    };
}

export function removerItem(registro) {

    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/pesoItem?id=${registro.id}`, {
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaItens(registro.id_peso, getState().auth.usuario.id_empresa));
			dispatch(getListaAnimal(getState().auth.usuario.id_empresa));
			dispatch(getLista(getState().auth.usuario.id_empresa));
        }).catch(e => {
            setErroAPI(e);
        });
    };
}

export function ratearPeso(id_empresa, id_peso, peso) {

	return async (dispatch, getState) => {
		let listaItens = [];
		await axios.get(`${consts.API_URL}/pesoItem?id_empresa=${id_empresa}&id_peso=${id_peso}`)
		.then(result => {
			listaItens = result.data;
		}).catch(e => {
			console.log(e);
		});
		let rateio = parseFloat((peso / listaItens.length).toFixed(4));
		let rateado = 0;
		listaItens = listaItens.map(item => {
			item.peso = rateio;
			rateado += rateio;
			return item;
		});
		let resto  = peso - rateado;
		while (resto != 0) {
			listaItens = listaItens.map(item => {
				if (resto >= 0.0001) {
					item.peso += 0.0001;
					resto -= 0.0001;
				} else if (resto <= -0.0001) {
					item.peso -= 0.0001;
					resto += 0.0001;
				} else {
					resto = 0;
				}
				return item;
			});
		}
		for (var i = 0; i < listaItens.length; i++) {
			let item = listaItens[i];
			await axios.post(`${consts.API_URL}/pesoItem`, {
				...item
			}).then(result => {
	        }).catch(e => {

	        });
		}

		setSuccess('Operação Realizada com sucesso.');
		dispatch(getListaItens(id_peso, getState().auth.usuario.id_empresa));
		dispatch(getListaAnimal(getState().auth.usuario.id_empresa));
		dispatch(getLista(getState().auth.usuario.id_empresa));

	}
}
