import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import AuthOrApp from './authOrApp'
import Dashboard from '../dashboard/dashboard';
import UsuarioTipo from '../usuarioTipo/usuarioTipo';
import Usuario from '../usuario/usuario';
import Pessoa from '../pessoa/pessoa';
import Brinco from '../brinco/brinco';
import Raca from '../raca/raca';
import Familia from '../familia/familia';
import Pelagem from '../pelagem/pelagem';
import Granja from '../granja/granja';
import Local from '../granja/local/local';
import Produto from '../produto/produto';
import ProdutoTipo from '../produtoTipo/produtoTipo';
import PrevisaoPeso from '../previsaoPeso/previsaoPeso';
import Lote from '../lote/lote';
import Animal from '../lote/animal/animal';
import Entrada from '../entrada/entrada';
import EstoqueAjuste from '../estoqueAjuste/estoqueAjuste';
import Estoque from '../estoque/estoque';
import AnimalVacina from '../animalVacina/animalVacina';
import Alimentacao from '../alimentacao/alimentacao';
import ProducaoRacao from '../producaoRacao/producaoRacao';
import Venda from '../venda/venda';
import CompraAnimal from '../compraAnimal/compraAnimal';
import Pesagem from '../pesagem/pesagem';
import Peso from '../peso/peso';
import Transferencia from '../transferencia/transferencia';
import Baixa from '../baixa/baixa';

import RelatorioInspetoriaVeterinaria from '../relatorioInspetoriaVeterinaria/relatorioInspetoriaVeterinaria';

export default props => (
	<div id='id-content-wrapper' className='content-wrapper'>
		<Switch>
			<Route exact path='/' component={Dashboard} />
			<Route path='/usuarioTipo' component={UsuarioTipo} />
			<Route path='/usuario' component={Usuario} />
			<Route path='/pessoa' component={Pessoa} />
			<Route path='/brinco' component={Brinco} />
			<Route path='/raca' component={Raca} />
			<Route path='/familia' component={Familia} />
			<Route path='/pelagem' component={Pelagem} />
			<Route path='/granja' component={Granja} />
			<Route path='/local' component={Local} />
			<Route path='/produto' component={Produto} />
			<Route path='/produtoTipo' component={ProdutoTipo} />
			<Route path='/previsaoPeso' component={PrevisaoPeso} />
			<Route path='/lote' component={Lote} />
			<Route path='/animal' component={Animal} />
			<Route path='/entrada' component={Entrada} />
			<Route path='/estoqueAjuste' component={EstoqueAjuste} />
			<Route path='/estoque' component={Estoque} />
			<Route path='/animalVacina' component={AnimalVacina} />
			<Route path='/alimentacao' component={Alimentacao} />
			<Route path='/producaoRacao' component={ProducaoRacao} />
			<Route path='/venda' component={Venda} />
			<Route path='/compraAnimal' component={CompraAnimal} />
			<Route path='/peso' component={Peso} />
			<Route path='/transferencia' component={Transferencia} />
			<Route path='/baixa' component={Baixa} />
			<Route path='/relatorioInspetoriaVeterinaria' component={RelatorioInspetoriaVeterinaria} />
			<Redirect from='*' to='/' />
		</Switch>
	</div>
);
