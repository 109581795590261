const INITIAL_STATE = { registro: {}, modoTela: 'lista', lista: [], listaGranja: [], listaRacao: [], listaMateriaPrima: [], listaProducaoRacaoItem: [] };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'PRODUCAO_RACAO_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};
        case 'PRODUCAO_RACAO_LISTADO':
            return { ...state, modoTela: 'lista', lista: action.payload.data };
		case 'PRODUCAO_RACAO_GRANJA_SELECT_LISTADO':
            return { ...state, listaGranja: action.payload.data };
		case 'PRODUCAO_RACAO_RACAO_SELECT_LISTADO':
            return { ...state, listaRacao: action.payload.data.filter(item => item.racao) }; //Apenas Ração
		case 'PRODUCAO_RACAO_MATERIA_PRIMA_SELECT_LISTADO':
			return { ...state, listaMateriaPrima: action.payload.data };
		case 'PRODUCAO_RACAO_ITEM_LISTADO':
			return { ...state, listaProducaoRacaoItem: action.payload.data || [] };
        default:
            return state;
    }
}
