import React from 'react'

export default props => (
	<button type={props.submit ? 'submit' : 'button'} className={`btn btn-flat btn-${props.type}`} onClick={props.event}>
		<i className={props.icon}> {props.text}</i>
	</button>
)

/*
primary
secondary
success
danger
warning
info
light
dark
link
*/
