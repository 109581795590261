import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import BoxButtonHeader from '../common/button/boxButtonHeader';
import Button from '../common/button/button';
import Form from './producaoRacaoForm';

import { setModoTela, initForm, salvar, excluir, getLista } from './producaoRacaoActions';
import { getDataAtual, getDataFormatada, getData, getDataHoraBanco, getDataHoraFormatada } from '../utils/dataUtils';

class ProducaoRacao extends Component {

    componentWillMount() {
        this.props.getLista(this.props.sessao.id_empresa);
    }

    render() {
        return (
            <div>
                <ContentHeader title='Produção da Ração' small='Cadastro' />
                <Content>
					{this.props.modoTela == 'cadastro' ? (
						<Form onSubmit={this.props.salvar} />
					) : null}
					{this.props.modoTela == 'lista' ? this.lista() : null}
                </Content>
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<Table>
					<THead>
						<Tr>
							<Th>Data e Hora</Th>
							<Th>Ração</Th>
							<Th>Matéria Prima</Th>
							<Th alignRight>Quantidade</Th>
							<Th alignCenter acoes>Ações</Th>
						</Tr>
					</THead>
					<TBody>
						{(this.props.lista).map(item => (
							<Tr key={item.id}>
								<Td>{getDataHoraFormatada(item.datahora_producao, 'DD/MM/YYYY HH:mm')}</Td>
								<Td>{item.nome_racao}</Td>
								<Td>
									{item.nome_materia_prima ? item.nome_materia_prima.map((mp, i) => {
										if (i+1 == item.nome_materia_prima.length) {
											return mp;
										} else {
											return `${mp}, `;
										}
									}) : ''}
								</Td>
								<Td alignRight>{item.quantidade.replace('.', ',')}</Td>
								<Td alignCenter>
									<ButtonTable
										type={'warning'}
										icon={'fa fa-pencil'}
										event={() => {
											this.props.setModoTela('cadastro', item);
											this.props.initForm({
												...item,
												quantidade: item.quantidade.replace('.', ',')
											});
										}} />
								</Td>
							</Tr>
						))}
					</TBody>
				</Table>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.producaoRacao.modoTela,
	lista: state.producaoRacao.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ProducaoRacao);
