import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import ContentHeader from '../../common/template/contentHeader';
import Content from '../../common/template/content';
import ContentCard from '../../common/template/contentCard';
import ContentCardHeader from '../../common/template/contentCardHeader';
import Row from '../../common/layout/row';
import Table from '../../common/table/table';
import THead from '../../common/table/tHead';
import Tr from '../../common/table/tr';
import Th from '../../common/table/th';
import TBody from '../../common/table/tBody';
import Td from '../../common/table/td';
import ButtonTable from '../../common/table/buttonTable';
import BoxButtonHeader from '../../common/button/boxButtonHeader';
import Button from '../../common/button/button';
import Form from './animalForm';

import { setModoTela, initForm, salvar, excluir, getLista } from './animalActions';

class Animal extends Component {

    componentWillMount() {
        this.props.getLista(this.props.id_lote, this.props.sessao.id_empresa);
    }

    render() {
        return (
            <div>
				{this.props.modoTela == 'cadastro' ? (
					<Form onSubmit={this.props.salvar} />
				) : null}
				{this.props.modoTela == 'exclusao' ? (
					<Form excluir onSubmit={this.props.excluir} />
				) : null}
				{this.props.modoTela == 'lista' ? this.lista() : null}
            </div>
        )
    }

	lista() {
		return (
			<ContentCard>
				<ContentCardHeader title='Animais' />
				<BoxButtonHeader>
					<Button
						text='Adicionar'
						type={'success'}
						icon={'fa fa-plus'}
						event={() => {

							let registro = {};

							if (this.props.lista.length > 0) {

								let primeiroAnimal = this.props.lista[0];
								let ultimoNumeroBrinco = parseInt(this.props.lista[this.props.lista.length - 1].numero_brinco) + 1;

								registro = {
									numero_brinco: ultimoNumeroBrinco, id_brinco: primeiroAnimal.id_brinco,
									id_fornecedor_origem: primeiroAnimal.id_fornecedor_origem,
									id_granja_origem: primeiroAnimal.id_granja_origem, id_local_origem: primeiroAnimal.id_local_origem,
									id_granja_destino: primeiroAnimal.id_granja_destino, id_local_destino: primeiroAnimal.id_local_destino
								};
							}

							this.props.setModoTela('cadastro', {});
							this.props.initForm({ ...registro, id_lote: this.props.id_lote });
						}} />
				</BoxButtonHeader>
				<Table>
					<THead>
						<Tr>
							<Th>Número do Brinco</Th>
							<Th>Brinco</Th>
							<Th>Mãe</Th>
							<Th>Granja</Th>
							<Th>Local</Th>
							<Th>Espécie</Th>
							<Th>Sexo</Th>
							<Th>Raca</Th>
							<Th>Pelagem</Th>
							<Th alignCenter>Idade</Th>
							<Th alignCenter>Peso</Th>
							<Th alignCenter acoes>Ações</Th>
						</Tr>
					</THead>
					<TBody>
						{this.props.lista.map(item => (
							<Tr key={item.id}>
								<Td>{item.numero_brinco}</Td>
								<Td>{item.brinco}</Td>
								<Td>{item.mae}</Td>
								<Td>{item.nome_granja}</Td>
								<Td>{item.nome_local}</Td>
								<Td>{item.nome_familia}</Td>
								<Td>{item.nome_sexo}</Td>
								<Td>{item.nome_raca}</Td>
								<Td>{item.nome_pelagem}</Td>
								<Td alignCenter>{parseInt(item.idade)} - {parseInt(item.idade_atual)}</Td>
								<Td alignCenter>{parseInt(item.peso_entrada)} - {item.peso_atual ? parseInt(item.peso_atual) : parseInt(item.peso_entrada)}</Td>
								<Td alignCenter>
									<ButtonTable
										type={'warning'}
										icon={'fa fa-pencil'}
										event={() => {
											this.props.setModoTela('cadastro', {...item, peso_entrada: item.peso_entrada.replace('.', ',') });
											this.props.initForm({ ...item, peso_entrada: item.peso_entrada.replace('.', ',') });
										}} />
									<ButtonTable
										type={'danger'}
										icon={'fa fa-trash-o'}
										event={() => {
											this.props.setModoTela('exclusao', { ...item, peso_entrada: item.peso_entrada.replace('.', ',') });
											this.props.initForm({ ...item, peso_entrada: item.peso_entrada.replace('.', ',') });
										}} />
								</Td>
							</Tr>
						))}
					</TBody>
				</Table>
			</ContentCard>
		);
	}


}

const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	modoTela: state.animal.modoTela,
	lista: state.animal.lista
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, initForm, salvar, excluir, getLista }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Animal);
