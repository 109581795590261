import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import Row from '../common/layout/row';
import Button from '../common/button/button';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import Select from '../common/form/select';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import BoxButtonHeader from '../common/button/boxButtonHeader';

import { setModoTela, getListaEstado, getListaCidade } from './granjaActions';
import Local from './local/local';

class GranjaForm extends Component {

    componentWillMount() {
		this.props.getListaEstado();
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		let listaCidades = this.props.listaCidades;

        return (
			<div>
				<ContentCard>
					{this.props.registro && this.props.registro.id && !this.props.excluir ?
					<BoxButtonHeader>
							<Button
								text='Voltar'
								type={'warning'}
								icon={'fa fa-chevron-left'}
								event={() => this.props.setModoTela('lista')} />
					</BoxButtonHeader>
					: null}
					<Form event={this.props.handleSubmit}>
						<Row>
							<Field
								name='nome'
								component={LabelAndInput}
								label='Nome *'
								placeholder='Informe o Nome'
								cols='12 12 12 6'
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='propriedade_rural'
								component={LabelAndInput}
								label='Propriedade Rural'
								placeholder='Informe o Propriedade Rural'
								cols='12 12 12 6'
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='grupo_produtor'
								component={LabelAndInput}
								label='Grupo Produtor'
								placeholder='Informe o Grupo Produtor'
								cols='12 12 12 6'
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='cpf'
								component={LabelAndInputMask}
								label='CPF'
								placeholder='Informe o CPF'
								mask="999.999.999-99"
								maskChar=" "
								cols='12 6 6 3'
								readOnly={readOnly} />

							<Field
								name='cnpj'
								component={LabelAndInputMask}
								label='CNPJ'
								placeholder='Informe o CNPJ'
								mask="99.999.999/9999-99"
								maskChar=" "
								cols='12 6 6 3'
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='inscricao_estadual'
								component={LabelAndInput}
								label='Inscrição Estadual'
								placeholder='Informe a Inscrição Estadual'
								cols='12 6 6 3'
								readOnly={readOnly} />

							<Field
								name='ir'
								component={LabelAndInput}
								label='IR'
								placeholder='Informe o IR'
								cols='12 6 6 3'
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='endereco'
								component={LabelAndInput}
								label='Endereço'
								placeholder='Informe o Endereço'
								cols='12 12 5 4'
								readOnly={readOnly} />

							<Field
								name='numero'
								component={LabelAndInput}
								label='Número'
								placeholder='Informe o Número'
								cols='12 4 3 2'
								readOnly={readOnly} />

							<Field
								name='complemento'
								component={LabelAndInput}
								label='Complemento'
								placeholder='Informe o Complemento'
								cols='12 8 4 3'
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='bairro'
								component={LabelAndInput}
								label='Bairro'
								placeholder='Informe o Bairro'
								cols='12 6 6 3'
								readOnly={readOnly} />

							<Field
								name='cep'
								component={LabelAndInputMask}
								label='CEP'
								placeholder='Informe o CEP'
								mask="99.999-999"
								maskChar=" "
								cols='12 6 6 3'
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='id_estado'
								component={Select}
								options={this.props.listaEstados}
								onChange={(event) => {
									this.props.getListaCidade(event.target.value);
								}}
								label='Estado'
								cols='12 6 6 3'
								placeholder='Selecione o Estado'
								readOnly={readOnly} />

							<Field
								name='id_cidade'
								component={Select}
								options={listaCidades}
								label='Cidade'
								cols='12 6 6 3'
								placeholder='Selecione a Cidade'
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='zona'
								component={LabelAndInput}
								label='Zona'
								placeholder='Informe a Zona'
								cols='12 12 12 6'
								readOnly={readOnly} />
						</Row>
						<Row>
							<Field
								name='telefone'
								component={LabelAndInputMask}
								label='Telefone'
								placeholder='Informe o Telefone'
								mask="(99) 9999-9999"
								maskChar=" "
								cols='12 6 6 3'
								readOnly={readOnly} />

							<Field
								name='celular'
								component={LabelAndInputMask}
								label='Celular'
								placeholder='Informe o Celular'
								mask="(99) 99999-9999"
								maskChar=" "
								cols='12 6 6 3'
								readOnly={readOnly} />
						</Row>

						<BoxButtonFooter>
							{this.props.excluir ? (
								<Button
									text='Excluir'
									submit type={'danger'}
									icon={'fa fa-trash'} />
							) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
							)}
							<Button
								text='Cancelar'
								type={'warning'}
								icon={'fa fa-chevron-left'}
								event={() => this.props.setModoTela('lista')} />
						</BoxButtonFooter>
					</Form>
				</ContentCard>
				{this.props.registro && this.props.registro.id && !this.props.excluir ?
				<Local id_granja={this.props.registro.id} />
				: null}
			</div>
        )
    }

}

GranjaForm = reduxForm({form: 'granjaForm', destroyOnUnmount: false})(GranjaForm);
const mapStateToProps = state => ({
	registro: state.granja.registro,
	listaLocais: [],
	listaEstados: state.granja.listaEstados,
	listaCidades: state.granja.listaCidades
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, getListaEstado, getListaCidade }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(GranjaForm);
