import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import ContentCardHeader from '../common/template/contentCardHeader';
import Row from '../common/layout/row';
import Button from '../common/button/button';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import LabelAndInputMask from '../common/form/labelAndInputMask';
import Select from '../common/form/select';
import Table from '../common/table/table';
import THead from '../common/table/tHead';
import Tr from '../common/table/tr';
import Th from '../common/table/th';
import TBody from '../common/table/tBody';
import Td from '../common/table/td';
import ButtonTable from '../common/table/buttonTable';
import BoxButtonHeader from '../common/button/boxButtonHeader';

import { setModoTela, getListaGranja } from './loteActions';
import Animal from './animal/animal';

class LoteForm extends Component {

    componentWillMount() {
		this.props.getListaGranja(this.props.sessao.id_empresa);
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

		let listaCidades = this.props.listaCidades;

        return (
			<div>
				<ContentCard>
					{this.props.registro && this.props.registro.id && !this.props.excluir ?
					<BoxButtonHeader>
							<Button
								text='Voltar'
								type={'warning'}
								icon={'fa fa-chevron-left'}
								event={() => this.props.setModoTela('lista')} />
					</BoxButtonHeader>
					: null}
					<Form event={this.props.handleSubmit}>
						<Row>
							<Field
								name='numero'
								component={LabelAndInput}
								label='Número *'
								placeholder='Informe o Número'
								cols='12 6 4 3'
								readOnly={readOnly} />
						</Row>

						<BoxButtonFooter>
							{this.props.excluir ? (
								<Button
									text='Excluir'
									submit type={'danger'}
									icon={'fa fa-trash'} />
							) : (
								<Button
									text='Salvar'
									submit
									type={'success'}
									icon={'fa fa-check'} />
							)}
							<Button
								text='Cancelar'
								type={'warning'}
								icon={'fa fa-chevron-left'}
								event={() => this.props.setModoTela('lista')} />
						</BoxButtonFooter>
					</Form>
				</ContentCard>
				{this.props.registro && this.props.registro.id && !this.props.excluir ?
				<Animal id_lote={this.props.registro.id} />
				: null}
			</div>
        )
    }

}

LoteForm = reduxForm({form: 'loteForm', destroyOnUnmount: false})(LoteForm);
const mapStateToProps = state => ({
	sessao: state.auth.usuario,
	registro: state.lote.registro,
	listaLocais: [],
	listaGranjas: state.lote.listaGranjas
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, getListaGranja }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(LoteForm);
