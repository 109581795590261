import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'PREVISAO_PESO_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = {}) {
    return [
        initialize('previsaoPesoForm', registro)
    ];
}

export function getLista() {
    const request = axios.get(`${consts.API_URL}/previsaoPeso`);
    return {
        type: 'PREVISAO_PESO_LISTADO',
        payload: request
    };
}

export function getListaFamilia() {
    const request = axios.get(`${consts.API_URL}/familia/listarselect`);
    return {
        type: 'PREVISAO_PESO_FAMILIA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaSexo() {
    const request = axios.get(`${consts.API_URL}/sexo/listarselect`);
    return {
        type: 'PREVISAO_PESO_SEXO_SELECT_LISTADO',
        payload: request
    };
}

export function getListaRaca() {
    const request = axios.get(`${consts.API_URL}/raca/listarselect`);
    return {
        type: 'PREVISAO_PESO_RACA_SELECT_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return dispatch => {
        axios.post(`${consts.API_URL}/previsaoPeso`, registro)
            .then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
                dispatch(setModoTela('lista'));
            })
            .catch(e => {
                setErroAPI(e);
            });
    };
}

export function excluir(registro) {
    return dispatch => {
        axios.delete(`${consts.API_URL}/previsaoPeso?id=${registro.id}`)
            .then(resp => {
                setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista());
                dispatch(setModoTela('lista'));
            })
            .catch(e => {
                setErroAPI(e);
            });
    };
}
