import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import ContentHeader from '../../common/template/contentHeader';
import Content from '../../common/template/content';
import ContentCard from '../../common/template/contentCard';
import ContentCardHeader from '../../common/template/contentCardHeader';
import Row from '../../common/layout/row';
import Button from '../../common/button/button';
import BoxButtonFooter from '../../common/button/boxButtonFooter';
import Form from '../../common/form/form';
import LabelAndInput from '../../common/form/labelAndInput';

import { setModoTela } from './localActions';

class LocalForm extends Component {

    componentWillMount() {

    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<ContentCardHeader title='Locais' />
				<Form event={this.props.handleSubmit}>
					<Row>
						<Field
							name='nome'
							component={LabelAndInput}
							label='Nome *'
							placeholder='Informe o Nome'
							cols='12 10 6 4'
							readOnly={readOnly} />
					</Row>
					<BoxButtonFooter>
						{this.props.excluir ? (
							<Button
								text='Excluir'
								submit type={'danger'}
								icon={'fa fa-trash'} />
						) : (
							<Button
								text='Salvar'
								submit
								type={'success'}
								icon={'fa fa-check'} />
						)}
						<Button
							text='Cancelar'
							type={'warning'}
							icon={'fa fa-chevron-left'}
							event={() => this.props.setModoTela('lista')} />
					</BoxButtonFooter>
				</Form>
			</ContentCard>
        )
    }


}

LocalForm = reduxForm({form: 'localForm', destroyOnUnmount: false})(LocalForm);
const mapStateToProps = state => ({
	registro: state.local.registro
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(LocalForm);
