import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import { getDataAtual, getDataFormatada, getData, getDataBanco, getDataHoraBanco, getDataHoraFormatada } from '../utils/dataUtils';

export function setModoTela(modo, registro = {}) {
    return {
        type: 'BAIXA_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = { data_morte: getDataAtual() }) {
	console.log(registro.data_morte);
    return [
        initialize('baixaForm', {
			...registro,
			data_morte: getDataHoraFormatada(registro.data_morte, 'DD/MM/YYYY')
		})
    ];
}

export function getLista(id_empresa) {
    const request = axios.get(`${consts.API_URL}/baixa?id_empresa=${id_empresa}`);
    return {
        type: 'BAIXA_LISTADO',
        payload: request
    };
}

export function getListaAnimal(id_empresa) {
    const request = axios.get(`${consts.API_URL}/animal/listarAbertos?id_empresa=${id_empresa}`);
    return {
        type: 'BAIXA_ANIMAL_SELECT_LISTADO',
        payload: request
    };
}

export function salvar(registro) {
    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/baixa`, {
				...registro,
				data_morte: getDataBanco(registro.data_morte),
				id_empresa: getState().auth.usuario.id_empresa
			})
            .then(resp => {
				setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(getState().auth.usuario.id_empresa));
                dispatch(setModoTela('lista'));
            })
            .catch(e => {
                setErroAPI(e);
            });
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/baixa?id=${registro.id}`)
            .then(resp => {
                setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(getState().auth.usuario.id_empresa));
                dispatch(setModoTela('lista'));
            })
            .catch(e => {
                setErroAPI(e);
            });
    };
}
