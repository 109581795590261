import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess, setError } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';
import { getDataAtual, getDataFormatada, getData, getDataBanco, getDataHoraBanco, getDataHoraFormatada } from '../utils/dataUtils';

export function setModoTela(modo, registro = { anexos: [] }) {
    return {
        type: 'VENDA_MODO_TELA',
        payload: {
			modo: modo,
			registro: registro
		}
    };
}
export function initForm(registro = { datahora_venda: getDataAtual(), anexos: [] }, formataData: true) {
    return [
        initialize('vendaForm', {
			...registro,
			anexos: registro.anexos || [],
			datahora_venda: formataData ? getDataHoraFormatada(registro.datahora_venda, 'DD/MM/YYYY HH:mm') : registro.datahora_venda
		})
    ];
}

export function getLista(id_empresa) {
    const request = axios.get(`${consts.API_URL}/venda?id_empresa=${id_empresa}`);
    return {
        type: 'VENDA_LISTADO',
        payload: request
    };
}

export function getListaGranja(id_empresa) {
    const request = axios.get(`${consts.API_URL}/granja/listarselect?id_empresa=${id_empresa}`);
    return {
        type: 'VENDA_GRANJA_SELECT_LISTADO',
        payload: request
    };
}

export function getListaCliente(id_empresa) {
    const request = axios.get(`${consts.API_URL}/pessoa/listarselect?id_empresa=${id_empresa}&cliente=true`);
    return {
        type: 'VENDA_CLIENTE_SELECT_LISTADO',
        payload: request
    };
}

export function getListaAnimal(id_granja, id_empresa) {
    const request = axios.get(`${consts.API_URL}/animal/listarAbertos?id_granja=${id_granja}&id_empresa=${id_empresa}`);
    return {
        type: 'VENDA_ANIMAL_SELECT_LISTADO',
        payload: request
    };
}

export function getListaItens(id_venda, id_empresa) {
    const request = axios.get(`${consts.API_URL}/vendaItem?id_venda=${id_venda}&id_empresa=${id_empresa}`);
    return {
        type: 'VENDA_ITEM_LISTADO',
        payload: request
    };
}

export function salvar(registro) {

    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/venda`, {
			...registro,
			datahora_venda: getDataHoraBanco(registro.datahora_venda),
			id_empresa: getState().auth.usuario.id_empresa
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getLista(getState().auth.usuario.id_empresa));
            dispatch(setModoTela('cadastro'));
			if (!registro.id) {
				registro.id = resp.data;
			}
			registro.datahora_venda = getDataHoraBanco(registro.datahora_venda);
			dispatch(initForm(registro, true));
			dispatch(getListaAnimal(registro.id_granja, getState().auth.usuario.id_empresa));
        }).catch(e => {
            setErroAPI(e);
        });
    };
}

export function excluir(registro) {
    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/venda?id=${registro.id}`)
            .then(resp => {
                setSuccess('Operação Realizada com sucesso.');
				dispatch(getLista(getState().auth.usuario.id_empresa));
                dispatch(setModoTela('lista'));
            })
            .catch(e => {
                setErroAPI(e);
            });
    };
}

export function adicionarItem(registro) {

    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/vendaItem`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaItens(registro.id_venda, getState().auth.usuario.id_empresa));
			dispatch(getListaAnimal(registro.id_granja, getState().auth.usuario.id_empresa));
			dispatch(getLista());
        }).catch(e => {
            setErroAPI(e);
        });
    };
}

export function alterarItem(registro) {

    return (dispatch, getState) => {
        axios.post(`${consts.API_URL}/vendaItem`, {
			...registro,
			id_empresa: getState().auth.usuario.id_empresa
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaItens(registro.id_venda, getState().auth.usuario.id_empresa));
			dispatch(getListaAnimal(registro.id_granja, getState().auth.usuario.id_empresa));
			dispatch(getLista(getState().auth.usuario.id_empresa));
        }).catch(e => {
            setErroAPI(e);
        });
    };
}

export function removerItem(registro) {

    return (dispatch, getState) => {
        axios.delete(`${consts.API_URL}/vendaItem?id=${registro.id}`, {
		}).then(resp => {
			setSuccess('Operação Realizada com sucesso.');
			dispatch(getListaItens(registro.id_venda, getState().auth.usuario.id_empresa));
			dispatch(getListaAnimal(registro.id_granja, getState().auth.usuario.id_empresa));
			dispatch(getLista(), getState().auth.usuario.id_empresa);
        }).catch(e => {
            setErroAPI(e);
        });
    };
}

export function ratearPesoVivo(id_empresa, id_venda, id_granja, peso) {

	return async (dispatch, getState) => {
		let listaItens = [];
		await axios.get(`${consts.API_URL}/vendaItem?id_empresa=${id_empresa}&id_venda=${id_venda}`)
		.then(result => {
			listaItens = result.data;
		}).catch(e => {
			console.log(e);
		});
		let rateio = parseFloat((peso / listaItens.length).toFixed(4));
		let rateado = 0;
		listaItens = listaItens.map(item => {
			item.peso_vivo = rateio;
			rateado += rateio;
			return item;
		});
		let resto  = parseFloat((peso - rateado).toFixed(4));
		while (resto != 0) {
			listaItens = listaItens.map(item => {
				if (resto >= 0.0001) {
					item.peso_vivo += 0.0001;
					resto -= 0.0001;
				} else if (resto <= -0.0001) {
					item.peso_vivo -= 0.0001;
					resto += 0.0001;
				} else {
					resto = 0;
				}
				return item;
			});
		}
		for (var i = 0; i < listaItens.length; i++) {
			let item = listaItens[i];
			await axios.post(`${consts.API_URL}/vendaItem`, {
				...item
			}).then(result => {
	        }).catch(e => {

	        });
		}

		setSuccess('Operação Realizada com sucesso.');
		dispatch(getListaItens(id_venda, getState().auth.usuario.id_empresa));
		dispatch(getListaAnimal(id_granja, getState().auth.usuario.id_empresa));
		dispatch(getLista(getState().auth.usuario.id_empresa));

	}

}

export function ratearPesoCarcaca(id_empresa, id_venda, id_granja, peso) {

	return async (dispatch, getState) => {
		let listaItens = [];
		await axios.get(`${consts.API_URL}/vendaItem?id_empresa=${id_empresa}&id_venda=${id_venda}`)
		.then(result => {
			listaItens = result.data;
		}).catch(e => {
			console.log(e);
		});
		let rateio = parseFloat((peso / listaItens.length).toFixed(4));
		let rateado = 0;
		listaItens = listaItens.map(item => {
			item.peso_carcaca = rateio;
			rateado += rateio;
			return item;
		});
		let resto  = parseFloat((peso - rateado).toFixed(4));
		while (resto != 0) {
			listaItens = listaItens.map(item => {
				if (resto >= 0.0001) {
					item.peso_carcaca += 0.0001;
					resto -= 0.0001;
				} else if (resto <= -0.0001) {
					item.peso_carcaca -= 0.0001;
					resto += 0.0001;
				} else {
					resto = 0;
				}
				return item;
			});
		}
		for (var i = 0; i < listaItens.length; i++) {
			let item = listaItens[i];
			await axios.post(`${consts.API_URL}/vendaItem`, {
				...item
			}).then(result => {
			}).catch(e => {

			});
		}

		setSuccess('Operação Realizada com sucesso.');
		dispatch(getListaItens(id_venda, getState().auth.usuario.id_empresa));
		dispatch(getListaAnimal(id_granja, getState().auth.usuario.id_empresa));
		dispatch(getLista(getState().auth.usuario.id_empresa));

	}

}

export function ratearValorTotal(id_empresa, id_venda, id_granja, valor) {

	return async (dispatch, getState) => {
		let listaItens = [];
		await axios.get(`${consts.API_URL}/vendaItem?id_empresa=${id_empresa}&id_venda=${id_venda}`)
		.then(result => {
			listaItens = result.data;
		}).catch(e => {
			console.log(e);
		});
		let rateio = parseFloat((valor / listaItens.length).toFixed(2));
		let rateado = 0;
		let pesoInformado = true;
		listaItens = listaItens.map(item => {
			if (!item.peso_vivo || item.peso_vivo <= 0 || !item.peso_carcaca || item.peso_carcaca <= 0) {
				pesoInformado = false;
			}
			item.valor_total = rateio;
			rateado += rateio;
			return item;
		});

		if (!pesoInformado) {
			setError("Primeiro informe os pesos");
			return;
		}

		let resto  = parseFloat((valor - parseFloat(rateado.toFixed(2))).toFixed(2));

		while (resto != 0) {
			listaItens = listaItens.map(item => {
				if (resto >= 0.01) {
					item.valor_total += 0.01;
					resto -= 0.01;
				} else if (resto <= -0.01) {
					item.valor_total -= 0.01;
					resto += 0.01;
				} else {
					resto = 0;
				}
				return item;
			});
		}

		listaItens = listaItens.map(item => {
			item.valor_vivo = item.valor_total / item.peso_vivo;
			item.valor_carcaca = item.valor_total / item.peso_carcaca;
			return item;
		});

		for (var i = 0; i < listaItens.length; i++) {
			let item = listaItens[i];
			await axios.post(`${consts.API_URL}/vendaItem`, {
				...item
			}).then(result => {
			}).catch(e => {

			});
		}

		setSuccess('Operação Realizada com sucesso.');
		dispatch(getListaItens(id_venda, getState().auth.usuario.id_empresa));
		dispatch(getListaAnimal(id_granja, getState().auth.usuario.id_empresa));
		dispatch(getLista(getState().auth.usuario.id_empresa));
	}

}
