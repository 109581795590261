import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';

import ContentHeader from '../common/template/contentHeader';
import Content from '../common/template/content';
import ContentCard from '../common/template/contentCard';
import Row from '../common/layout/row';
import Button from '../common/button/button';
import BoxButtonFooter from '../common/button/boxButtonFooter';
import Form from '../common/form/form';
import LabelAndInput from '../common/form/labelAndInput';
import Select from '../common/form/select';
import LabelAndInputNumber from '../common/form/labelAndInputNumber';

import { setModoTela, getListaBrincoTipo } from './brincoActions';

class BrincoForm extends Component {

    componentWillMount() {
		this.props.getListaBrincoTipo();
    }

    render() {

		let readOnly = this.props.excluir ? 'readOnly' : '';

        return (
			<ContentCard>
				<Form event={this.props.handleSubmit}>
					<Row>
						<Field
							name='cor'
							component={LabelAndInput}
							label='Cor *'
							placeholder='Informe a Cor'
							cols='12 12 8 4'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='id_brinco_tipo'
							component={Select}
							options={this.props.listaBrincoTipo}
							label='Tipo *'
							cols='12 12 8 4'
							placeholder='Selecione o Tipo'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='marca'
							component={LabelAndInput}
							label='Marca *'
							placeholder='Informe a Marca'
							cols='12 12 8 4'
							readOnly={readOnly} />
					</Row>
					<Row>
						<Field
							name='comprimento'
							component={LabelAndInputNumber}
							label='Comprimento (cm)*'
							placeholder='Informe o Comprimento'
							casas={2}
							cols='12 6 4 2'
							readOnly={readOnly} />

						<Field
							name='altura'
							component={LabelAndInputNumber}
							label='Altura (cm)*'
							placeholder='Informe a Altura'
							casas={2}
							cols='12 6 4 2'
							readOnly={readOnly} />
					</Row>
					<BoxButtonFooter>
						{this.props.excluir ? (
							<Button
								text='Excluir'
								submit type={'danger'}
								icon={'fa fa-trash'} />
						) : (
							<Button
								text='Salvar'
								submit
								type={'success'}
								icon={'fa fa-check'} />
						)}
						<Button
							text='Cancelar'
							type={'warning'}
							icon={'fa fa-chevron-left'}
							event={() => this.props.setModoTela('lista')} />
					</BoxButtonFooter>
				</Form>
			</ContentCard>
        )
    }


}

BrincoForm = reduxForm({form: 'brincoForm', destroyOnUnmount: false})(BrincoForm);
const mapStateToProps = state => ({
	listaBrincoTipo: state.brinco.listaBrincoTipo,
	registro: state.brinco.registro
});
const mapDispatchToProps = dispatch => bindActionCreators({ setModoTela, getListaBrincoTipo }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(BrincoForm);
