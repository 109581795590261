const INITIAL_STATE = { registro: {}, modoTela: 'lista', lista: [], listaGranja: [], listaLote: [], listaAnimal: [], listaVacina: [] };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'ANIMAL_VACINA_MODO_TELA':
            return { ...state,
				modoTela: action.payload.modo,
				registro: action.payload.registro
			};
        case 'ANIMAL_VACINA_LISTADO':
            return { ...state, modoTela: 'lista', lista: action.payload.data };
		case 'ANIMAL_VACINA_GRANJA_SELECT_LISTADO':
            return { ...state, listaGranja: action.payload.data };
		case 'ANIMAL_VACINA_LOTE_SELECT_LISTADO':
            return { ...state, listaLote: action.payload.data };
		case 'ANIMAL_VACINA_ANIMAL_SELECT_LISTADO':
            return { ...state, listaAnimal: action.payload.data };
		case 'ANIMAL_VACINA_VACINA_SELECT_LISTADO':
            return { ...state, listaVacina: action.payload.data.filter(item => item.vacina) }; //Apenas vacinas
        default:
            return state;
    }
}
