const INITIAL_STATE = { lista: [], listaGranja: [] };

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
		case 'RELATORIO_INSPETORIA_VETERINARIA_LISTADO':
            return { ...state, lista: action.payload.data };
		case 'RELATORIO_INSPETORIA_VETERINARIA_GRANJA_LISTADO':
			return { ...state, listaGranja: action.payload.data };
        default:
            return state;
    }
}
