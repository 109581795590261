import axios from 'axios';
import { showTabs, selectTab } from '../common/tab/tabActions';
import consts from '../consts';
import { setErroAPI, setSuccess } from '../common/toast/toast';
import { reset as resetForm, initialize } from 'redux-form';

export function getLista(id_granja, id_empresa) {
    const request = axios.get(`${consts.API_URL}/animal/relatorioInspetoriaVeterinaria?id_granja=${id_granja}&id_empresa=${id_empresa}`);
    return {
        type: 'RELATORIO_INSPETORIA_VETERINARIA_LISTADO',
        payload: request
    };
}

export function getListaGranja(id_empresa) {
    const request = axios.get(`${consts.API_URL}/granja?id_empresa=${id_empresa}`);
    return {
        type: 'RELATORIO_INSPETORIA_VETERINARIA_GRANJA_LISTADO',
        payload: request
    };
}
